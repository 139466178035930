import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionItem,
  AccordionHeader,
} from "components/Accordion";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { setLastItemDeleted } from "pages/Report/CustomReport/CustomReportSlice";
import { useDispatch } from "react-redux";

const SectionList = (props: any) => {
  const { sections, renderItem, renderSectionHeader } = props;
  const [sectionId, setSectionId] = useState<string[]>([]);
  const { t } = useTranslation();

  const toggle = (id: string) => {
    if (sectionId.includes(id)) {
      setSectionId(sectionId.filter((item) => item !== id));
    } else {
      setSectionId([...sectionId, id]);
    }
  };
  const dispatch = useDispatch()

  useEffect(() => {
    let updatedList = sectionId;
    sections.forEach((element: any) => {
      if (element.data.length) {
        updatedList = [...updatedList, element.title];
      } else {
        updatedList = updatedList.filter((item) => item !== element.title);
      }
      setSectionId(updatedList);
      if(sections[0]?.data?.length + sections[1]?.data?.length == 1){
        dispatch(setLastItemDeleted(true))
      }
    });
  }, [sections[0]?.data?.length, sections[1]?.data?.length]);

  return (
    <Accordion
      className={classNames("section-list-container")}
      flush
      open={sectionId}
      toggle={toggle}
    >
      {sections.map(({ title, data }: any) => {
        return (
          <AccordionItem className={classNames("section-container")}>
            <AccordionHeader
              targetId={title}
              tag="button"
              id={title + "heading"}
              aria-expanded={sectionId === title}
              aria-controls="sidenavcollapseOne"
              className={classNames(
                "w-100 btn btn-text section-heading aui-sidenav-acc-header",
                {
                  parentHighlight: sectionId === title,
                }
              )}
            >
              {renderSectionHeader(`${title} (${data?.length})`)}
              <i className="acc-btn-arrow aha-icon-arrow-down"></i>
            </AccordionHeader>
            <AccordionBody
              className="section-item"
              accordionId={title}
              id={title + "heading"}
            >
              {data.map(renderItem)}
              {data?.length === 0 && (
                <React.Fragment key={13}>
                  <div className="mt-3 pb-3 text-center justify-content-center nodatafound">
                    <h3>{t<string>("noDataFound")}</h3>
                  </div>
                </React.Fragment>
              )}
            </AccordionBody>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

export default SectionList;
