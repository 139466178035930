import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface LeftPanelState {
  showMyReports: boolean;
  deleteReport: Object;
  activeFilter: any;
  showPopup: boolean;
  savedFilterflag: boolean
  count: any,
  dataUpdated: any
  shouldFetchFilter: boolean
}

export const initialState: LeftPanelState = {
  showMyReports: false,
  deleteReport: {
    id: "",
    activeId: "",
    searchFilter: ""
  },
  activeFilter: null,
  showPopup: false,
  savedFilterflag: false,
  count: 0,
  dataUpdated: false,
  shouldFetchFilter: true,
};

const adminLeftPanelSlice = createSlice({
  name: "leftPanel",
  initialState,
  reducers: {
    setShowMyReports: (state, action) => {
      state.showMyReports = action.payload;
    },
    setDataUpdated: (state, action) => {
      state.dataUpdated = action.payload;
    },
    setDeleteReport: (state, action) => {
      state.deleteReport = action.payload;
    },
    setActiveFilter: (state, action) => {
      state.activeFilter = action.payload;
    },
    setShowPopup: (state, action) => {
      state.showPopup = action.payload;
    },
    setSavedFilterflag: (state, action) => {
      state.savedFilterflag = action.payload;
    },
    setCount: (state, action: any) => {
      state.count = state.count + 1
    },
    setShouldFetchFilter: (state, action: any) => {
      state.shouldFetchFilter = action.payload
    },
  },
});

const { actions, reducer } = adminLeftPanelSlice;

export const { setShowMyReports, setDeleteReport, setSavedFilterflag, setActiveFilter, setShowPopup, setCount, setDataUpdated, setShouldFetchFilter } = actions;
export default reducer;