
export const CONSTANTS = {
  PENDING: "PENDING",
  REGISTERED: "APPROVED",
  INTERNATIONAL: "INTERNATIONAL",
  DOMESTIC: "DOMESTIC",
  LOCAL_STORE_KEY: "#%$##%$#",

  PAGINATION_LIMIT: 5,
  KEY_ENTER: 13,
  KEY_SPACEBAR: 32,
  KEY_ESCAPE: 27,
  KEY_DOWN: 40,
  KEY_UP: 38,

  TableRecordSizeList: [10, 25, 50, 100],
  SortOptions: ["Latest", "A-Z"],
  ORDER_BY_LATEST : "Latest",
  ORDER_BY_ATOZ : "A-Z",
  SORT_BY_UPDATED_AT_DESC : "updated_at DESC",
  SORT_BY_FIRST_NAME_ASC : "first_name asc",
  SORT_BY_TITLE_ASC : "title asc",

  ACCREDITATION_PAGEID: "ACCRD",
  CERTIFICATE_PAGEID: "CERT",
  USERLIST_PAGEID: "USER",
  ERRORLIST_PAGE: "ERRORLIST",
  AUDITLOG_PAGEID: "AUDITlOG",
  REPORTLOG_PAGEID: "REPORTLOG",
  CUSTOMREPORTLOG_PAGEID:"CUSTOMREPORTLOG",
  BATCHLOG_PAGEID: "BATCHlOG",
  ACTIVITYLOG_PAGEID: "ACTIVITYlOG",
  VENDORLOG_PAGEID: "VENDORLOG",
  SUCCESS_ACTIVITY: "ACTIVITYLIST",
  PREDEFINED_SCHEDULER:"PREDEFINEDSCHEDULER",
  CUSTOM_SCHEDULER:"CUSTOMSCHEDULER",
  CDR_FIELDS: ["cdrCpeLevel", "cdrPerformanceIndicators", "courseApprovalNumber"],
  ARCHIVED_LEARNER_LIST: "ARCHIVED_LEARNER_LIST",
  ROLES: [
    {id : 1, name: "Super Admin", code: "SUPER_ADMIN"},
    {id : 2, name: "Tenant Admin", code: "ADMIN"},
    {id : 3, name: "Reporting Admin", code: "REPORTING_ADMIN"},
    {id : 4, name: "Vendor Admin", code: "VENDOR_ADMIN"},
  ],
  CDS: "CDS",
  MOC_REPORTS: ["moc_abim", "moc_abp", "users_claiming_moc_scheduled"],
  ACCREDIDATIONS: {
    amaPmaCategory1 : "AMA PMA Category 1",
  },
  PREDEFINED_REPORT: "PREDEFINED_REPORT_PAGEID",
  REPORT_SENDER_EMAIL: "noreply@ceemail.heartblr.org",
  SCHEDULER_LIST_PAGEID: "SCHEDULERLIST",
  CUSTOM_REPORT_PAGEID: "CUSTOM_REPORT_PAGEID",
  VENDOR_ADMIN: "VENDOR_ADMIN",
  SUPER_ADMIN: "SUPER_ADMIN",
  SCHEDULE_DELETED: "Schedule Deleted",
  REPORT_FILTER_MODIFIED : "Report Filter Modified",
  CUSTOM_REPORT_SCHEDULE_CREATED:"Custom Report Schedule Created",
  CUSTOM_REPORT_SCHEDULE_UPDATED:"Custom Report Schedule Updated",
  PREDEFINED_ACTIVITY_UPDATE: [
    {name: "Report Previewed", code: "Report_Previewed"},
    {name: "Report Exported", code: "Report_Exported"},
    {name: "Report Export in progress", code: "Report_Export_in_progress"},
    {name: "Report Export Notified", code: "Report_Export_Notified"},
    {name: "Report Downloaded", code: "Report_Downloaded"},
  ],
  SCHEDULER_ACTIVITY_UPDATE: [
    {name: "Schedule Created", code: "Schedule_Created"},
    {name: "Schedule Updated", code: "Schedule_Updated"},
    {name: "Schedule Enabled", code: "Schedule_Enabled"},
    {name: "Schedule Disabled", code: "Schedule_Disabled"},
    {name: "Schedule Executed", code: "Schedule_Executed"},
    {name: "Schedule Notified", code: "Schedule_Notified"},    
  ],
  CUSTOM_PREDEFINED_ACTIVITY_UPDATE: [
    {name: "Report Previewed", code: "Report_Previewed"},
    {name: "Report Exported", code: "Report_Exported"},
    {name: "Report Export Notified", code: "Report_Export_Notified"},
    {name: "Report Downloaded", code: "Report_Downloaded"},
    {name: "Report Filter Added", code: "Report_Filter_Added"},
    {name: "Report Filter Modified", code: "Report_Filter_Modified"},
    {name: "Report Filter Previewed", code: "Report_Filter_Previewed"},
    {name: "Report Filter Deleted", code: "Report_Filter_Deleted"},
  ],
  CUSTOM_SCHEDULER_ACTIVITY_UPDATE: [
    {name: "Custom Report Schedule Created", code: "Custom_Report_Schedule_Created"},
    {name: "Custom Report Schedule Updated", code: "Custom_Report_Schedule_Updated"},
    {name: "Custom Report Schedule Enabled", code: "Custom_Report_Schedule_Enabled"},
    {name: "Custom Report Schedule Disabled", code: "Custom_Report_Schedule_Disabled"},
    {name: "Custom Report Schedule Executed", code: "Custom_Report_Schedule_Executed"},
    {name: "Custom Report Schedule Notified", code: "Custom_Report_Schedule_Notified"},    
  ],
  AUDITLOG_ACTIVITY_UPDATE: [
    {name: "Added", code: "Added"},
    {name: "Modified", code: "Modified"},
    {name: "Deleted", code: "Deleted"},
    {name: "Status Changed", code: "Status Changed"},
  ],
  END_DATE_TIME_ERROR: "End Date and Time can not be past date and time."
} as const;

export default CONSTANTS;
