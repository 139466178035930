import React from "react";
import PropTypes from "prop-types";
import InputTextStyle from "./Styled";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";

interface Props {
  elementObject: any;
  handleData: any;
  isReactSelect?: boolean;
  isPaginateSelect?: boolean;
}

export const RenderInputSelect = ({ elementObject, handleData, isReactSelect, isPaginateSelect }: Props) => {
  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      maxHeight: "5rem",
      overflowY: "auto",
      borderColor: '#777979',
      borderRadius: "2px",
      outline: state.isFocused && "2px solid #E17509",
      boxShadow: state.isFocused && "0 0 4px 4px #e17509",
      height:40,
      overflow: "hidden"

    }),
    option: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: "white",
      color: "#707070",
      cursor: "pointer"
    })
  };
  if (isReactSelect) {
    return (
      <InputTextStyle>
        <Select
          id={elementObject?.id}
          name={elementObject?.name}
          aria-label={elementObject?.name}
          placeholder={elementObject?.placeholder}
          defaultValue={elementObject?.defaultValue}
          value={elementObject?.selected}
          getOptionValue={(item: any) => {
            return (item?.code)
          }}
          getOptionLabel={(item: any) => {
            return (item?.name ?? item?.code)
          }}
          options={elementObject?.data}
          closeMenuOnSelect={true}
          onChange={handleData}
          isDisabled={elementObject?.disabled ?? false}
          className="multi"
          styles={customStyles}
        // @ts-ignore
        />
      </InputTextStyle>
    )
  }
  if (isPaginateSelect) {
    return (
      <InputTextStyle>
        <AsyncPaginate
          id={elementObject?.id}
          debounceTimeout={500}
          name={elementObject?.name}
          loadOptions={elementObject?.loadOptions}
          key ={elementObject?.key}
          value={elementObject.selected}
          getOptionValue={(item: any) => {
            return (item?.code)
          }}
          getOptionLabel={(item: any) => {
            return (item?.name ?? item?.code)
          }}
          onChange={handleData}
          additional={{
            page: 1,
          }}
          isSearchable={true}
          aria-label={elementObject?.id}
          isDisabled={elementObject?.isDisabled}
          required={true}
          cacheUniqs={[ elementObject?.clearCacheState ]}
          styles={customStyles}
          className="multi"
        />
      </InputTextStyle>
    )
  }
  else {
    return (
      <InputTextStyle>
        <select
          id={elementObject.id}
          data-testid={elementObject.id}
          name={elementObject.name}
          aria-label={elementObject.name}
          className={`form-control ${elementObject.className ? elementObject.className : ""}`}
          onChange={handleData}
          value={elementObject.selected}
          required={
            elementObject.required !== undefined ? elementObject.required : true
          }
          disabled={elementObject.disabled ?? false}
        >
          <option key="select" value={elementObject.defaultValue !== undefined ? elementObject.defaultValue : ""}>
            {elementObject.placeholder !== undefined ? elementObject.placeholder : "Select"}
          </option>
          {elementObject.data.map((item: any) => (
            <option key={item.code} value={item.code} className={item.code === elementObject.defaultValue ? "d-none" : ""} >
              {item.name ? item.name : item.code}
            </option>
          ))}
        </select>
      </InputTextStyle>
    );
  }
};

RenderInputSelect.propTypes = {
  elementObject: PropTypes.object,
  handleData: PropTypes.func,
  isReactSelect: PropTypes.bool
};

RenderInputSelect.defaultProps = {
  elementObject: { id: "", name: "", value: "", className: "" },
  handleData: () => null,
  isReactSelect: false
};

export default React.memo(RenderInputSelect);
