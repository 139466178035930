import React, { useEffect, useRef, useState } from "react";
import { CustomReportsWrapper, GenerateReportWrapper } from "./Styled";
import { useTranslation } from "react-i18next";
import Select, { ActionMeta } from "react-select";
import Option from "components/InputFields/RenderMultiSelectInputField";
import RenderInputSelect from "components/InputFields/RenderInputSelect";
import { Routes, reportFrequency } from "common/constants";
import _, { lowerCase } from "lodash";
import CustDateRangePicker from "components/DateRangePicker";
import { useDispatch, useSelector } from "react-redux";
import {
  ListAccreditationByFilter,
  ListVendorByFilter,
  ListCustomReportByFilters,
  GetProgramListByFilter,
  GetCustomReportFilterById,
  ListCustomReportColumns,
  GetClassificationList,
} from "services/api/report.api";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { format, subDays, subMonths, subYears } from "date-fns";
import { GetCertificationByFilter } from "services/api/Certification.api";
import ModalPopupComp from "components/ModalPopup";
import { ModalHeader } from "components/ModalPopup/ModalHeader";
import {
  CustomReportStateConstants,
  useCustomReportContext,
} from "./CustomReportContext/CustomReportContext";
import {
  CONSTANTS,
  filterFields,
  components as comp,
  CategoryOption,
} from "common/constants/customReports";
import { useHistory, useParams } from "react-router-dom";
import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import { DateRangePicker } from "rsuite";
import { DateRange } from "rsuite/DateRangePicker";
import {
  setVendor,
  setProgram,
  setAccreditation,
  setClassification,
  setCertificate,
  setFrequency,
  setDateRange,
  setNotifyMe,
  setSelectedFields,
  setCategory,
  setInitialSavedFilterData,
  setIsInvalidId,
  setErrorMessage,
  setPreviewData,
  setCustomReportAPIReqBody,
  setTableColumns,
  setPaginationData,
  setReportId,
  setReportName,
  setShowViewReport,
  setProgramMode,
  setProgramSubType,
  setDateType,
  setReportType,
  setExportReportButtonDisable,
  updateReportDetail,
  setDisableSaveReportFilter,
  updateSaveReportState,
} from "./CustomReportSlice";
import { listEquals } from "common/utils/EqualList";
import { SaveReportBody } from "./modals/SaveReportModalBody";
import { ChangeReportModalBody } from "./modals/ChangeReportModalBody";
import { setReportDetails } from "./CustomReportSlice";
import { setCurrentTab } from "pages/ReportScheduler/SchedulerSlice";
import { IColumn } from "./SelectFields/SelectFields";
import { typeFlatNodes, Node } from "./SelectFields/SelectedFieldsConfig";
import { setShouldFetchFilter, setShowMyReports } from "components/AdminLeftPanel/LeftPanelSlice";
import { AsyncPaginate, ShouldLoadMore } from "react-select-async-paginate";

import { setActiveFilter } from "components/AdminLeftPanel/LeftPanelSlice";
import {
  convertListToString,
  convertStringToList,
} from "common/utils/commonFunctionality";
import { handleAPIError } from "common/ErrorHandler";
import { dateType } from "common/constants/dateType";
import PageHeading from "components/PageHeading/PageHeading";
import ViewReport from "./ViewReport";
import { GetProgramType } from "services/api/Program.api";
import classNames from "classnames";
import {
  setAccreditationsList,
  setCertificatesList,
  setClassificationsList,
  setProgramSubTypeList,
  setProgramTypeList,
  setProgramTypeSubTypeList,
  setProgramsList,
  setVendorsList,
} from "./DropdownData";

const { afterToday } = DateRangePicker;

const navigateToListPage = () => { };

const modalPopupProps = {
  popupDiscardFunc: navigateToListPage,
};

interface FlatNode {
  id: string;
  label: string;
  code: string;
  refName?: string;
  children?: FlatNode[];
}

type AdminCustomReportProps = {
  routeState?: any;
  getData?: any;
};

const AdminCustomReport = ({ routeState, getData }: AdminCustomReportProps) => {

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const routeParams: any = useParams();

  const [flatNodes, setFlatNodes] = useState<typeFlatNodes>({});

  const [savedReportName, setSavedReportName] = useState("");
  const [reportNameErrorClass, setReportNameErrorClass] =
    useState<boolean>(false);
  const [showError, setShowError] = useState("");
  const saveReportNameRef = useRef<HTMLInputElement>(null);
  const [paginations, setPaginations] = useState({
    pageNumber: 1,
    pageSize: 25,
  });

  const customReportingState = useSelector((state: any) => {
    return state.customReport;
  });

  const OptionsData = useSelector((state: any) => {
    return state.dropdownOptionsData;
  });

  const firstRenderRef = useRef(true);

  useEffect(() => {
    // Accessibility on date range
    if (document.getElementsByClassName("rs-picker-toggle-placeholder")[0]) {
      document
        .getElementsByClassName("rs-picker-toggle-placeholder")[0]
        .removeAttribute("aria-placeholder");
    }

    if (document.getElementsByClassName("rs-picker-toggle-textbox")[0]) {
      document.getElementsByClassName("rs-picker-toggle-textbox")[0].tabIndex =
        -1;
    }

    if (document.getElementsByClassName("rs-btn-disabled")[0]) {
      document
        .getElementsByClassName("rs-btn-disabled")[0]
        .removeAttribute("disabled");
    }
  }, [customReportingState.reportDetails?.frequency]);

  const [vendors, setVendors] = useState<any>();

  const [programs, setPrograms] = useState<any>();

  const [accreditations, setAccreditations] = useState<any>();

  const [classifications, setClassifications] = useState<any>();

  const [certificates, setCertificates] = useState<any>();

  const [dateRangeValue, setDateRangeValue] = useState<any>([]);

  const [dateRangeErrorClass, setDateRangeErrorClass] =
    useState<boolean>(false);

  const [frequencyErrorClass, setFrequencyErrorClass] =
    useState<boolean>(false);

  const [programSearchResult, setProgramSearchResult] = useState([]);

  // selected categories at page load
  const [categories, setCategories] = useState<string[]>(
    customReportingState.reportDetails?.categories?.map(
      (category: CategoryOption) => _.lowerCase(category?.code)
    ) ?? []
  );

  const getProgramApiParams = (
    vendor: string,
    accreditation: string,
    classification: string,
    certificate: string,
    type: string,
    subType: string,
    dateType: string,
    startDate: string,
    endDate: string,
    program: string = "",
    searchProgram: string = "",
    pageSize: number = 25,
    pageNumber: number = 1,
    pageOrder: string = ""
  ) => {
    let reportType = "custom_report";
    const programParams = {
      pageNumber: pageNumber,
      pageSize: pageSize,
      pageOrder: pageOrder,
      vendor: "",
      program: "",
      searchProgram: "",
      accreditation: "",
      classification: "",
      certificate: "",
      reportType: "",
      type: "",
      subType: "",
      dateType: "",
      startDate: "",
      endDate: "",
    };

    if (!_.isEmpty(type)) {
      programParams.type = type;
    }

    if (!_.isEmpty(subType)) {
      programParams.subType = subType;
    }

    if (!_.isEmpty(dateType)) {
      programParams.dateType = dateType;
    }

    if (!_.isEmpty(startDate)) {
      programParams.startDate = startDate;
    }

    if (!_.isEmpty(endDate)) {
      programParams.endDate = endDate;
    }

    if (!_.isEmpty(reportType)) {
      programParams.reportType = reportType;
    }

    if (!_.isEmpty(vendor)) {
      programParams.vendor = vendor;
    }

    if (!_.isEmpty(accreditation)) {
      programParams.accreditation = accreditation;
    }

    if (!_.isEmpty(classification)) {
      programParams.classification = classification;
    }

    if (!_.isEmpty(certificate)) {
      programParams.certificate = certificate;
    }

    if (!_.isEmpty(program)) {
      programParams.program = program;
    }

    if (!_.isEmpty(searchProgram)) {
      programParams.searchProgram = searchProgram;
    }

    return programParams;
  };

  const getPrograms = async (
    vendor: string,
    accreditation: string,
    classification: string,
    certificate: string,
    type: string,
    subType: string,
    dateType: string,
    startDate: string,
    endDate: string,
    program: string = "",
    isProgramFieldSelected: boolean = false
  ) => {
    // Return if program field is not selected

    if (!isProgramFieldSelected) {
      return;
    }

    const programParams = getProgramApiParams(
      vendor,
      accreditation,
      classification,
      certificate,
      type,
      subType,
      dateType,
      startDate,
      endDate,
      program
    );

    let programs = [];

    try {
      dispatch(
        setPageLoadingStatus({
          isPageLoading: true,
        })
      );
      if (!_.isEmpty(program)) {
        // to get the selected programs with program filter
        const response = await GetProgramListByFilter(programParams);
        programs = response?.data?.data?.programs;
      }
      let programsWithFilter = programs.map((program: any) => program.code);
      let selectedPrograms: any = program
        .split(",")
        ?.filter((selectedProgram: any) =>
          programsWithFilter.includes(selectedProgram)
        ); //Remove those programs from selected list which are not part of filter(Accreditation, certificate, classification)

      // dispatch(
      //   setProgram({
      //     ...customReportingState,
      //     programs: selectedPrograms.join(","),
      //   })
      // );

      dispatch(setProgramsList(programs));
      setPrograms(programs ?? []);

      dispatch(
        setPageLoadingStatus({
          isPageLoading: false,
        })
      );
    } catch (e) {
      setPrograms([]);
      dispatch(
        setPageLoadingStatus({
          isPageLoading: false,
        })
      );
    }
  };

  // Set the category state to the initial selected categories.
  useEffect(() => {
    setCategories(
      customReportingState.reportDetails?.categories?.map(
        (category: CategoryOption) => _.lowerCase(category?.code)
      )
    );
  }, [customReportingState.customReportApiReqBody]);

  const nodeHasChildren = (node: FlatNode) => {
    return Array.isArray(node.children);
  };

  const flattenNodes = (
    nodes: FlatNode[] | undefined,
    parent: FlatNode,
    depth = 0
  ) => {
    let tempObject: any = {};

    if (!Array.isArray(nodes) || nodes.length === 0) {
      return;
    }

    //Create a flat object for every node
    nodes.forEach((node: FlatNode, index) => {
      const isParent = nodeHasChildren(node);

      // Check for duplicate value
      if (flatNodes[node.id] !== undefined) {
        console.error("Every field has to have an unique Value");
      }

      tempObject[node.id] = {
        label: node.label,
        id: node.id,
        code: node.code,
        children: node.children,
        parent,
        isChild: parent?.id !== undefined,
        isParent,
        isLeaf: !isParent,
        disabled: false,
        treeDepth: depth,
        index,
        refName: node?.refName,
      };

      const res = flattenNodes(node.children, node, depth + 1);
      tempObject = {
        ...tempObject,
        ...res,
      };
    });
    return tempObject;
  };

  const getNode = (id: any, flatNodes: any): Node => {
    return flatNodes[id];
  };

  // Search value within field and generate column properties
  const getColumn = (
    value: string,
    flatNodes: any,
    field: any
    //@ts-ignore
  ): IColumn => {
    if (field?.isLeaf && field.code == value) {
      let columnObj: IColumn = {
        name: field.parent.label + " - " + field.label,
        isChecked: true,
        mandatory: true,
        class: "code-cls",
        propertyName: field.refName,
      };

      if (field.refName.includes("date") || field.refName.includes("Date")) {
        columnObj.isDate = true;
      } else {
        columnObj.isDate = false;
      }
      return columnObj;
    }
    if (!field.isLeaf) {
      let childrenList = field.children;

      for (const element of childrenList) {
        let childNode = getNode(element.id, flatNodes);

        let column: IColumn = getColumn(value, flatNodes, childNode);
        if (column) {
          return column;
        }
      }
    }
  };

  const GetID = (filter: any, toFind: string): any => {
    for (const element of filter) {
      if (element.code === toFind) {
        return element.id;
      }

      if (element.hasOwnProperty("children")) {
        const id = GetID(element.children, toFind);
        if (id) {
          return id;
        }
      }
    }
  };

  const updateSelectedFields = (fields: any, customFields: any) => {
    let selected: string[] = [];

    for (const [key, value] of Object.entries(customFields)) {
      if (Array.isArray(value) && value.length > 0) {
        let filteredField: any[] = [];

        if (key === "programAccreditations") {
          filteredField = fields
            .filter((item: any) => item.code === CONSTANTS.accreditationsUnderPrograms)[0]
            .children.filter((item: any) => item.code === key)[0];
        } else {
          filteredField = fields.filter((item: any) => item.code === key || item.code === CONSTANTS.accreditationsUnderPrograms)[0];
        }

        value.forEach((item: string) => {
          const id = GetID(filteredField?.children, item);
          selected = [...selected, id];
        });
      }
    }

    return selected;
  };

  const filterField = (fields: any[], categories: CategoryOption[]) => {
    const isAdminReport = customReportingState?.isAdminReport;
    const isActivityReport = customReportingState?.isActivityReport;
    const isSuccessActivity = customReportingState?.isSuccessActivity;
    const isErrorActivity = customReportingState?.isErrorActivity;
    let filteredFields: any[] = [];

    if (isAdminReport) {
      categories.forEach((category: CategoryOption) => {
        switch (category.code) {
          case CONSTANTS.Vendors:
            const vendorField = fields.filter(
              (field: any) => field.code === _.camelCase(CONSTANTS.Vendors)
            );
            filteredFields = [...filteredFields, ...vendorField];
            break;
          case CONSTANTS.Programs:
            const programField = fields.filter(
              (field: any) => field.code === _.camelCase(CONSTANTS.Programs)
            );
            filteredFields = [...filteredFields, ...programField];
            break;
          case CONSTANTS.Accreditations:
            const accreditationField = fields.filter(
              (field: any) =>
                field.code === _.camelCase(CONSTANTS.Accreditations)
            );
            filteredFields = [...filteredFields, ...accreditationField];
            break;
          case CONSTANTS.Classifications:
            const classificationField = fields.filter(
              (field: any) =>
                field.code === _.camelCase(CONSTANTS.Classifications)
            );
            filteredFields = [...filteredFields, ...classificationField];
            break;
          case CONSTANTS.Certificates:
            const certificateField = fields.filter(
              (field: any) => field.code === _.camelCase(CONSTANTS.Certificates)
            );
            filteredFields = [...filteredFields, ...certificateField];
            break;
          case CONSTANTS.learnerDetail:
            const learnerDetailField = fields.filter(
              (field: any) => field.code === _.camelCase(CONSTANTS.learnerDetail)
            );
            filteredFields = [...filteredFields, ...learnerDetailField];
            break;
          case CONSTANTS.accreditationsUnderPrograms:
            const accreditationProgramField = fields.filter(
              (field: any) =>
                field.code ===
                _.camelCase(CONSTANTS.accreditationsUnderPrograms)
            );
            filteredFields = [...filteredFields, ...accreditationProgramField];
            break;
        }
      });
    } else if (isActivityReport) {
      if (isSuccessActivity) {
        const successActivityField = fields.filter(
          (field: any) =>
            field.code === _.camelCase(CONSTANTS.successActivities)
        );
        filteredFields = [...filteredFields, ...successActivityField];
      }

      if (isErrorActivity) {
        const errorActivityField = fields.filter(
          (field: any) => field.code === _.camelCase(CONSTANTS.errorActivities)
        );
        filteredFields = [...filteredFields, ...errorActivityField];
      }
    }

    return filteredFields;
  };

  const setSavedReportTableColumns = async (
    customField: any,
    categories: CategoryOption[]
  ) => {

    try {
      dispatch(
        setPageLoadingStatus({
          isPageLoading: true,
        })
      );

      const response = await ListCustomReportColumns();

      const fields: FlatNode[] =
        response.data?.data?.customColumn?.custom_report_column?.customFields;
      const filteredFields = filterField(fields, categories);

      const selectedColumns = updateSelectedFields(filteredFields, customField);

      const parent: FlatNode = {
        id: "",
        label: "",
        code: "",
      };

      let tempState = flattenNodes(filteredFields, parent);
      setFlatNodes(tempState);

      let columns: IColumn[] = [];

      // Generate the table columns

      if (customField?.vendors && customField?.vendors.length > 0) {
        let fieldValue = Object.values(tempState).filter(
          (field: any) => field.code === "vendors"
        );

        customField?.vendors.forEach((value: string) => {
          let col = getColumn(value, tempState, fieldValue[0]);
          columns.push(col);
        });
      }

      if (customField?.programs && customField?.programs.length > 0) {
        let fieldValue = Object.values(tempState).filter(
          (field: any) => field.code === "programs" || CONSTANTS.accreditationsUnderPrograms
        );

        customField?.programs.forEach((value: string) => {
          let col = getColumn(value, tempState, fieldValue[0]);
          columns.push(col);
        });
      }

      if (
        customField?.programAccreditations &&
        customField?.programAccreditations.length > 0
      ) {
        let fieldValue = Object.values(tempState).filter(
          (field: any) => field.code === "programAccreditations"
        );

        customField?.programAccreditations.forEach((value: string) => {
          let col = getColumn(value, tempState, fieldValue[0]);
          columns.push(col);
        });
      }

      if (
        customField?.learnerDetails &&
        customField?.learnerDetails.length > 0
      ) {
        let fieldValue = Object.values(tempState).filter(
          (field: any) => field.code === "learnerDetails"
        );

        customField?.learnerDetails.forEach((value: string) => {
          let col = getColumn(value, tempState, fieldValue[0]);
          columns.push(col);
        });
      }

      if (
        customField?.accreditations &&
        customField?.accreditations.length > 0
      ) {
        let fieldValue = Object.values(tempState).filter(
          (field: any) => field.code === "accreditations"
        );

        customField?.accreditations.forEach((value: string) => {
          let col = getColumn(value, tempState, fieldValue[0]);
          columns.push(col);
        });
      }

      if (
        customField?.classifications &&
        customField?.classifications.length > 0
      ) {
        let fieldValue = Object.values(tempState).filter(
          (field: any) => field.code === "classifications"
        );

        customField?.classifications.forEach((value: string) => {
          let col = getColumn(value, tempState, fieldValue[0]);
          columns.push(col);
        });
      }

      if (customField?.certificates && customField?.certificates.length > 0) {
        let fieldValue = Object.values(tempState).filter(
          (field: any) => field.code === "certificates"
        );

        customField?.certificates.forEach((value: string) => {
          let col = getColumn(value, tempState, fieldValue[0]);
          columns.push(col);
        });
      }


      return [columns, selectedColumns];
    } catch (error) {
      console.error(error);
    }
  };

  const getViewReportData = async (viewReportReqBody: any) => {
    let reportData = {
      ...viewReportReqBody,
    };
    const frequency = viewReportReqBody?.filters?.frequency;

    if (frequency !== "custom_range") {
      const endDate = format(new Date(), "yyyy-MM-dd");
      let startDate: any = "";

      switch (frequency) {
        case "weekly":
          startDate = subDays(new Date(), 7);
          break;
        case "monthly":
          startDate = subMonths(new Date(), 1);
          break;
        case "quarterly":
          startDate = subMonths(new Date(), 3);
          break;
        case "half_yearly":
          startDate = subMonths(new Date(), 6);
          break;
        case "yearly":
          startDate = subYears(new Date(), 1);
          break;
        default:
          startDate = "";
      }

      startDate = startDate !== "" ? format(startDate, "yyyy-MM-dd") : "";
    }
    try {
      dispatch(
        setPageLoadingStatus({
          isPageLoading: true,
        })
      );
      const response = await ListCustomReportByFilters(
        {
          ...reportData,
          reportId: parseInt(routeParams?.id),
        },
        {
          pageNumber: 1,
          pageOffset: 1,
          pageSize: 25,
        }
      );

      if (response?.data?.status === 200) {
        const previewData = response?.data;
        const pagination = response?.data?._pagination;

        return [previewData, pagination]
      }
    } catch (err: any) {
      dispatch(
        setPageLoadingStatus({
          isPageLoading: false,
        })
      );
      store.dispatch(
        showToast({
          title: "CustomReport",
          message: err.response?.data?.error?.details[0]?.message,
        })
      );
    }
  };

  const UpdateCustomReportWithFilters = async () => {
    if (getData.customReportFilter) {
      const { filters, name: reportName } = getData?.customReportFilter
      let viewReportReqBody = {
        customField: filters.customField,
        filters: filters.filters,
      };

      let selectedCategories: CategoryOption[] = [];

      // Generate the categories object .
      for (let cat of filters.filters.categories) {
        comp.forEach((item: CategoryOption) => {
          if (item.code === _.capitalize(cat)) {
            selectedCategories.push(item);
          }
        });
      }

      if (selectedCategories.length === 0) {
        for (const [key, value] of Object.entries(filters.customField)) {
          if (key === "learnerDetails") {
            selectedCategories = [
              ...selectedCategories,
              ...comp.filter(
                (item: CategoryOption) => item.code === CONSTANTS.learnerDetail
              ),
            ];
          } else if (key === "programAccreditations") {
            selectedCategories = [
              ...selectedCategories,
              ...comp.filter(
                (item: CategoryOption) =>
                  item.code === CONSTANTS.accreditationsUnderPrograms
              ),
            ];
          }
        }
      }


      const [columns, selectedColumns]: any = await setSavedReportTableColumns(
        viewReportReqBody.customField,
        selectedCategories
      );


      const [previewData, pagination]: any = await getViewReportData(viewReportReqBody);



      dispatch(updateSaveReportState({
        ...customReportingState,
        customReportApiReqBody: viewReportReqBody,
        tableColumns: columns ?? [],
        reportName: reportName,
        previewData: previewData ?? {},
        pagination: pagination ?? {},
        reportID: routeParams?.id,
        showViewReport: true,
        selectedFields: selectedColumns ?? [],
        reportDetails: {
          ...customReportingState.reportDetails,
          categories: selectedCategories,
          vendors: filters?.filters?.vendors.join(","),
          programs: filters?.filters?.programs.join(","),
          accreditations: filters?.filters?.accreditations.join(","),
          certificates: filters?.filters?.certificates.join(","),
          classifications: filters?.filters?.classifications.join(","),
          frequency: filters.filters.frequency,
          startDate: filters?.filters?.startDate,
          endDate: filters?.filters?.endDate,
          dateType: filters?.filters?.dateType,
          programMode: filters?.filters?.programType ?? [],
          programSubType: filters?.filters?.programSubType ?? [],
        },
        notificationDetails: {
          ...customReportingState.notificationDetails,
          notifyMe: filters.filters.notifyMe,
        },
        initialSavedFilterData: {
          categories: selectedCategories,
          vendors: filters?.filters?.vendors.join(","),
          programs: filters?.filters?.programs.join(","),
          accreditations: filters?.filters?.accreditations.join(","),
          classifications: filters?.filters?.classifications.join(","),
          certificates: filters?.filters?.certificates.join(","),
          frequency: filters.filters.frequency,
          startDate: filters?.filters?.startDate,
          endDate: filters?.filters?.endDate,
          dateType: filters?.filters?.dateType,
          programMode: filters?.filters?.programType,
          programSubType: filters?.filters?.programSubType,
          isSavedFilterSelected: true,
          errorType: "",
          mismatchedErrors:
            filters.filters[CONSTANTS.errorType] === CONSTANTS.mismatch
              ? filters.filters[CONSTANTS.errorFields]
              : "",

          rejectedErrors:
            filters.filters[CONSTANTS.errorType] === CONSTANTS.rejected
              ? filters.filters[CONSTANTS.errorFields]
              : "",
        },
      }))

      for (const category of selectedCategories) {
        if (category.code === CONSTANTS.Vendors) {
          await getVendors();
        } else if (category.code === CONSTANTS.Accreditations) {
          await getAccreditations();
        } else if (category.code === CONSTANTS.accreditationsUnderPrograms) {
          await fetchProgramTypes();
          await getPrograms(
            convertListToString(filters?.filters?.vendors),
            convertListToString(filters?.filters?.accreditations),
            convertListToString(filters?.filters?.classifications),
            convertListToString(filters?.filters?.certificates),
            filters.filters.programType,
            convertListToString(filters.filters.programSubType),
            filters.filters.dateType,
            filters.filters.startDate,
            filters.filters.endDate,
            convertListToString(filters?.filters?.programs),
            selectedCategories.some(
              (category) => category.code === CONSTANTS.accreditationsUnderPrograms
            )
          );
          await getAccreditations();
          await getCertificates(
            convertListToString(filters?.filters?.accreditations),
            filters?.filters?.certificates
          );
          await getClassifications(
            convertListToString(filters?.filters?.certificates),
            filters?.filters?.classifications
          );

        } else if (category.code === CONSTANTS.Certificates) {
          await getCertificates(
            convertListToString(filters?.filters?.accreditations),
            filters?.filters?.certificates
          );
        } else if (category.code === CONSTANTS.Classifications) {
          await getClassifications(
            convertListToString(filters?.filters?.certificates),
            filters?.filters?.classifications
          );
        } else if (category.code === CONSTANTS.Programs) {
          await getPrograms(
            convertListToString(filters?.filters?.vendors),
            convertListToString(filters?.filters?.accreditations),
            convertListToString(filters?.filters?.classifications),
            convertListToString(filters?.filters?.certificates),
            filters.filters.programType,
            filters.filters.programSubType,
            filters.filters.dateType,
            filters.filters.startDate,
            filters.filters.endDate,
            convertListToString(filters?.filters?.programs),
            selectedCategories.some(
              (category) => category.code === CONSTANTS.Programs
            )
          );

          await fetchProgramTypes()
        }
      }
      handleLoaderClose();
    }
  };

  const fetchFilter = async () => {

    if (getData?.customReportFilter?.name == leftPanelState?.activeFilter?.name || !leftPanelState?.activeFilter?.name) {
      await UpdateCustomReportWithFilters();
    }
  };

  //to reload the dropdown coming back from select fields page - normal report
  useEffect(() => {
    (async () => {
      try {
        if (!routeParams?.id) {
          for (const category of customReportingState.reportDetails
            .categories) {
            if (category.code === CONSTANTS.Vendors) {
              await getVendors();
            } else if (category.code == CONSTANTS.Accreditations) {
              await getAccreditations();
            } else if (category.code === CONSTANTS.Certificates) {
              await getCertificates(
                customReportingState.reportDetails?.accreditations
              );
            } else if (category.code === CONSTANTS.Classifications) {
              await getClassifications(
                customReportingState.reportDetails?.certificates
              );
            } else if (category.code === CONSTANTS.Programs) {
              await getPrograms(
                customReportingState.reportDetails?.vendors,
                customReportingState.reportDetails?.accreditations,
                customReportingState.reportDetails?.classifications,
                customReportingState.reportDetails?.certificates,
                customReportingState.reportDetails?.programMode,
                customReportingState.reportDetails?.programSubType.join(
                  ","
                ),
                customReportingState.reportDetails?.dateType,
                customReportingState.reportDetails?.startDate,
                customReportingState.reportDetails?.endDate,
                customReportingState.reportDetails?.programs,
                customReportingState.reportDetails?.categories.some(
                  (category: CategoryOption) =>
                    category.code === CONSTANTS.Programs
                )
              );
              await fetchProgramTypes();
            } else if (
              category.code === CONSTANTS.accreditationsUnderPrograms
            ) {
              getAccreditations();
              getCertificates(
                customReportingState.reportDetails?.accreditations
              );
              getClassifications(
                customReportingState.reportDetails?.certificates
              );
              getPrograms(
                customReportingState.reportDetails?.vendors,
                customReportingState.reportDetails?.accreditations,
                customReportingState.reportDetails?.classifications,
                customReportingState.reportDetails?.certificates,
                customReportingState.reportDetails?.programMode,
                customReportingState.reportDetails?.programSubType.join(
                  ","
                ),
                customReportingState.reportDetails?.dateType,
                customReportingState.reportDetails?.startDate,
                customReportingState.reportDetails?.endDate,
                customReportingState.reportDetails?.programs,
                customReportingState.reportDetails?.categories.some(
                  (category: CategoryOption) =>
                    category.code === CONSTANTS.accreditationsUnderPrograms
                )
              );
              await fetchProgramTypes();
            }
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        handleLoaderClose();
      }
    })();

  }, []);

  const leftPanelState = useSelector((state: any) => {
    return state.adminLeftPanel;
  });
  //to reload the dropdown coming back from select fields page - Saved report

  useEffect(() => {
    // Set the dateRangeValue on component load from context
    if (
      customReportingState.reportDetails?.startDate !== "" &&
      customReportingState.reportDetails?.endDate !== ""
    ) {
      setDateRangeValue([
        new Date(customReportingState.reportDetails?.startDate),
        new Date(customReportingState.reportDetails?.endDate),
      ]);
    }
  }, []);

  useEffect(() => {

    if (routeParams?.id && leftPanelState.shouldFetchFilter && getData?.customReportFilter) {
      fetchFilter();
    }
  }, [leftPanelState?.dataUpdated, leftPanelState?.count, getData?.customReportFilter?.name])






  const filterSelectedMultiSelect = (
    previousList: any[],
    values: any[] | undefined,
    key: string
  ) => {
    let selected = [];

    if (previousList?.length === 0) {
      return [];
    }

    if (key === CONSTANTS.Programs) {
      // Append the search result value in previousList
      programSearchResult.forEach((item: any) => {
        let programExists = false;
        previousList.forEach((program: any) => {
          if (program.code === item.code) {
            programExists = true;
          }
        });
        if (!programExists) {
          previousList.push(item);
        }
      });
    }

    if (
      values &&
      Array.isArray(values) &&
      Array.isArray(previousList) &&
      values?.length > 0 &&
      previousList.length > 0
    ) {
      selected = previousList.filter((item: any) => {
        if (_.includes(values, item.code)) {
          return item;
        }
      });
    }
    return selected;
  };

  const handleLoaderClose = () => {
    dispatch(
      setPageLoadingStatus({
        isPageLoading: false,
      })
    );
  };

  const getVendors = async () => {
    try {
      dispatch(
        setPageLoadingStatus({
          isPageLoading: true,
        })
      );
      const vendorParams = {
        pageNumber: "",
        pageSize: 500,
        pageOrder: "updated_at DESC",
      };
      const response = await ListVendorByFilter(vendorParams);
      const vendorsData = response?.data?.data?.vendors;
      dispatch(setVendorsList(vendorsData));
      setVendors(vendorsData ?? []);
    } catch (e) {
      dispatch(
        setPageLoadingStatus({
          isPageLoading: false,
        })
      );
    }
  };

  const uniqueCheck = (list1: any, list2: any) => {
    if (typeof list1 === "undefined" || list1 === null) {
      list1 = [];
    }

    if (typeof list2 === "undefined" || list2 === null) {
      list2 = [];
    }

    const uniqueList = list1.filter((item: any) => {
      let containsInSavedList = false;

      for (let i = 0; i < list2.length; i++) {
        if (list2[i].code === item.code) {
          containsInSavedList = true;
          break;
        }
      }

      if (!containsInSavedList) {
        return true;
      } else {
        return false;
      }
    });

    return [...uniqueList, ...list2];
  };

  const shouldLoadMore: ShouldLoadMore = (
    scrollHeight,
    clientHeight,
    scrollTop
  ) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;

    return bottomBorder < scrollTop;
  };

  const programLoadOptions = async (
    searchQuery: any,
    loadedOptions: any,
    { page }: any
  ) => {
    const programParams = getProgramApiParams(
      customReportingState.reportDetails?.vendors,
      customReportingState.reportDetails?.accreditations,
      customReportingState.reportDetails?.classifications,
      customReportingState.reportDetails?.certificates,
      customReportingState.reportDetails?.programMode,
      customReportingState.reportDetails?.programSubType.join(
        ","
      ),
      customReportingState.reportDetails?.dateType,
      customReportingState.reportDetails?.startDate,
      customReportingState.reportDetails?.endDate,
      "",
      searchQuery,
      25,
      page
    );

    const response: any = await GetProgramListByFilter(programParams);
    let loadedPrograms = response.data?.data?.programs;

    dispatch(setProgramsList(uniqueCheck(loadedPrograms, programs)));

    setPrograms(uniqueCheck(loadedPrograms, programs));

    return {
      options: loadedPrograms,
      hasMore: Math.ceil(response?.data?._pagination?.totalCount / 25) > page,
      additional: {
        page: page + 1,
      },
    };
  };

  const getAccreditations = async () => {
    try {
      dispatch(
        setPageLoadingStatus({
          isPageLoading: true,
        })
      );
      const accreditationParams = {
        pageNumber: "",
        pageSize: 500,
        pageOrder: "updated_at DESC",
      };
      const response = await ListAccreditationByFilter(accreditationParams);
      const accreditationsData = response?.data?.data?.accreditations;
      const accreditationList = accreditationsData.map(
        (accreditation: any) => ({
          id: accreditation.id,
          code: accreditation.code,
        })
      );

      dispatch(setAccreditationsList(accreditationList));
      setAccreditations(accreditationList ?? []);
    } catch (e) {
      setAccreditations([]);
      dispatch(
        setPageLoadingStatus({
          isPageLoading: false,
        })
      );
    }
  };

  const getClassifications = async (
    certificates?: string,
    isClassificationSelected: boolean = true
  ) => {
    if (!isClassificationSelected) {
      return;
    }

    try {
      dispatch(
        setPageLoadingStatus({
          isPageLoading: true,
        })
      );
      const classificationParams = {
        pageNumber: "",
        pageSize: 500,
        pageOrder: "updated_at DESC",
        certificates: certificates,
      };

      const response = await GetClassificationList(classificationParams);
      const classificationsData = response?.data?.data?.categories;
      const classificationList = classificationsData
        ? classificationsData.map((classification: any) => ({
          id: classification.id,
          code: classification.code,
          name: classification.name,
        }))
        : [];
      dispatch(setClassificationsList(classificationList));

      const initialSelectedClassifications = convertStringToList(
        customReportingState.reportDetails?.classifications,
        ","
      );

      let selectedClassifications = initialSelectedClassifications?.filter(
        (code: any) => classificationList.some((obj: any) => obj.code === code)
      );

      dispatch(setClassificationsList(classificationList));
      setClassifications(classificationList ?? []);
      handleLoaderClose();
    } catch (e) {
      setClassifications([]);
      dispatch(
        setPageLoadingStatus({
          isPageLoading: false,
        })
      );
    }
  };

  const getCertificates = async (
    accreditationCode: any = "",
    certificates: any = [],
    isCertificateFieldSelected: boolean = true
  ) => {
    if (!isCertificateFieldSelected) {
      return;
    }

    try {
      dispatch(
        setPageLoadingStatus({
          isPageLoading: true,
        })
      );
      const certificateParams = {
        pageNumber: "",
        pageSize: 500,
        pageOrder: "updated_at DESC",
        accreditationCode: accreditationCode,
      };

      const response = await GetCertificationByFilter(certificateParams);
      const certificatesData = response?.data?.data?.certificateTemplates;
      const certificateList = certificatesData
        ? certificatesData.map((certificate: any) => ({
          id: certificate.id,
          code: certificate.code,
          name: certificate.name,
        }))
        : [];
      dispatch(setCertificatesList(certificateList));

      let initialCertificate =
        convertStringToList(
          customReportingState.reportDetails?.certificates,
          ","
        ) &&
          convertStringToList(
            customReportingState.reportDetails?.certificates,
            ","
          )?.length > 0
          ? convertStringToList(
            customReportingState.reportDetails?.certificates,
            ","
          )
          : certificates;

      let selectedCertificates = initialCertificate?.filter((code: any) =>
        certificateList.some((obj) => obj.code === code)
      );



      const isClassificationFieldSelected =
        customReportingState.reportDetails?.categories.some(
          (category: CategoryOption) =>
            category.code === CONSTANTS.Classifications ||
            category.code === CONSTANTS.accreditationsUnderPrograms
        );

      if (
        !_.isEmpty(accreditationCode) &&
        initialCertificate.length !== selectedCertificates.length
      ) {
        getClassifications(
          selectedCertificates.join(","),
          isClassificationFieldSelected
        );
      }
      dispatch(setCertificatesList(certificateList));
      setCertificates(certificateList ?? []);
      handleLoaderClose();
    } catch (e) {
      setCertificates([]);
      dispatch(
        setPageLoadingStatus({
          isPageLoading: false,
        })
      );
    }
  };

  const dateRangeChange = (dates: any) => {
    let startDate = "";
    let endDate = "";

    if (!_.isEmpty(dates)) {
      startDate = dates.split("~")[0];
      endDate = dates.split("~")[1];

      setDateRangeValue([new Date(startDate), new Date(endDate)]);

      dispatch(
        setDateRange({
          ...customReportingState,
          startDate,
          endDate,
        })
      );

      getPrograms(
        customReportingState.reportDetails?.vendors,
        customReportingState.reportDetails?.accreditations,
        customReportingState.reportDetails?.classifications,
        customReportingState.reportDetails?.certificates,
        customReportingState.reportDetails?.programMode,
        customReportingState.reportDetails?.programSubType.join(
          ","
        ),
        customReportingState.reportDetails?.dateType,
        startDate,
        endDate,
        customReportingState.reportDetails?.programs,
        customReportingState.reportDetails?.categories.some(
          (category: CategoryOption) =>
            category.code === CONSTANTS.Programs ||
            category.code === CONSTANTS.accreditationsUnderPrograms
        )
      );
    } else {
      dispatch(
        setDateRange({
          ...customReportingState,
          startDate: "",
          endDate: "",
        })
      );
      getPrograms(
        customReportingState.reportDetails?.vendors,
        customReportingState.reportDetails?.accreditations,
        customReportingState.reportDetails?.classifications,
        customReportingState.reportDetails?.certificates,
        customReportingState.reportDetails?.programMode,
        customReportingState.reportDetails?.programSubType.join(
          ","
        ),
        customReportingState.reportDetails?.dateType,
        "",
        "",
        customReportingState.reportDetails?.programs,
        customReportingState.reportDetails?.categories.some(
          (category: CategoryOption) =>
            category.code === CONSTANTS.Programs ||
            category.code === CONSTANTS.accreditationsUnderPrograms
        )
      );

      setDateRangeValue([]);
      let apiReqBody = customReportingState.customReportApiReqBody;

      dispatch(
        setCustomReportAPIReqBody({
          ...customReportingState,
          customReportApiReqBody: apiReqBody,
        })
      );
    }
  };

  const handleFrequencyChange = (event: any) => {
    dispatch(setDisableSaveReportFilter(true));
    let frequency = event.target.value;
    setDateRangeErrorClass(false);
    setFrequencyErrorClass(false);

    if (frequency === "custom_range" || frequency === "") {
      setDateRangeValue([]);

      dispatch(
        setFrequency({
          ...customReportingState,
          frequency: frequency,
          startDate: "",
          endDate: "",
        })
      );

      getPrograms(
        customReportingState.reportDetails?.vendors,
        customReportingState.reportDetails?.accreditations,
        customReportingState.reportDetails?.classifications,
        customReportingState.reportDetails?.certificates,
        customReportingState.reportDetails?.programMode,
        customReportingState.reportDetails?.programSubType.join(
          ","
        ),
        customReportingState.reportDetails?.dateType,
        "",
        "",
        customReportingState.reportDetails?.programs,
        customReportingState.reportDetails?.categories.some(
          (category: CategoryOption) =>
            category.code === CONSTANTS.Programs ||
            category.code === CONSTANTS.accreditationsUnderPrograms
        )
      );
    }

    const endDate = format(new Date(), "yyyy-MM-dd");
    let startDate: any = Date;

    switch (frequency) {
      case "weekly":
        startDate = subDays(new Date(), 7);
        break;
      case "monthly":
        startDate = subMonths(new Date(), 1);
        break;
      case "quarterly":
        startDate = subMonths(new Date(), 3);
        break;
      case "half_yearly":
        startDate = subMonths(new Date(), 6);
        break;
      case "yearly":
        startDate = subYears(new Date(), 1);
        break;
      default:
        return null;
    }

    startDate = format(startDate, "yyyy-MM-dd");

    dispatch(
      setFrequency({
        ...customReportingState,
        frequency: frequency,
        startDate: startDate,
        endDate: endDate,
      })
    );

    getPrograms(
      customReportingState.reportDetails?.vendors,
      customReportingState.reportDetails?.accreditations,
      customReportingState.reportDetails?.classifications,
      customReportingState.reportDetails?.certificates,
      customReportingState.reportDetails?.programMode,
      customReportingState.reportDetails?.programSubType.join(
        ","
      ),
      customReportingState.reportDetails?.dateType,
      startDate,
      endDate,
      customReportingState.reportDetails?.programs,
      customReportingState.reportDetails?.categories.some(
        (category: CategoryOption) =>
          category.code === CONSTANTS.Programs ||
          category.code === CONSTANTS.accreditationsUnderPrograms
      )
    );
  };

  function getCodeList(categories: any) {
    return categories.reduce((result: any, element: any) => {
      if (element.code === CONSTANTS.accreditationsUnderPrograms) {
        result.push("programAccreditations");
      } else if (element.code === CONSTANTS.learnerDetail) {
        result.push("learnerDetails");
      } else {
        result.push(_.lowerCase(element.code));
      }
      return result;
    }, []);
  }

  const handleViewReport = async () => {
    if (!isDisabledViewReport() && validateFrequency()) {
      const category = getCodeList(
        customReportingState.reportDetails?.categories
      );
      let requestBody: any = {};

      let customFields: any = JSON.parse(
        JSON.stringify(
          customReportingState.customReportApiReqBody.customField ?? {}
        )
      );

      let filters: any = {
        categories: category,
        vendors: convertStringToList(
          customReportingState.reportDetails?.vendors,
          ","
        ),
        programs: convertStringToList(
          customReportingState.reportDetails?.programs,
          ","
        ),
        accreditations: convertStringToList(
          customReportingState.reportDetails?.accreditations,
          ","
        ),
        certificates: convertStringToList(
          customReportingState.reportDetails?.certificates,
          ","
        ),
        classifications: convertStringToList(
          customReportingState.reportDetails?.classifications,
          ","
        ),
        frequency: customReportingState.reportDetails?.frequency,
        startDate: customReportingState.reportDetails?.startDate,
        endDate: customReportingState.reportDetails?.endDate,
        dateType: customReportingState.reportDetails?.dateType,
        programType: customReportingState.reportDetails?.programMode,
        programSubType: customReportingState.reportDetails?.programSubType
      };

      requestBody = {
        ...requestBody,
        customField: customFields,
        filters: filters,
      };


      dispatch(
        setCustomReportAPIReqBody({
          ...customReportingState,
          customReportApiReqBody: requestBody,
        })
      );

      dispatch(
        setPageLoadingStatus({
          isPageLoading: true,
        })
      );

      if (customReportingState.reportDetails?.frequency !== "custom_range") {
        const endDate = format(new Date(), "yyyy-MM-dd");
        let startDate: any = Date;

        switch (customReportingState.reportDetails?.frequency) {
          case "weekly":
            startDate = subDays(new Date(), 7);
            break;
          case "monthly":
            startDate = subMonths(new Date(), 1);
            break;
          case "quarterly":
            startDate = subMonths(new Date(), 3);
            break;
          case "half_yearly":
            startDate = subMonths(new Date(), 6);
            break;
          case "yearly":
            startDate = subYears(new Date(), 1);
            break;
          default:
            startDate = "";
        }

        startDate = startDate !== "" ? format(startDate, "yyyy-MM-dd") : "";

        if (startDate !== "") {
          requestBody = {
            ...requestBody,
            filters: {
              ...requestBody.filters,
              startDate: startDate,
              endDate: endDate,
            },
          };
        } else {
          requestBody = {
            ...requestBody,
            filters: {
              ...requestBody.filters,
              startDate: "",
              endDate: "",
            },
          };
        }
      }

      // If it is a saved report send the name of the report also
      if (routeParams?.id) {
        requestBody = {
          ...requestBody,
          reportId: parseInt(routeParams.id),
        };
      }

      if (
        customReportingState.reportDetails?.frequency === "custom_range" &&
        filters.startDate === "" &&
        filters.endDate === ""
      ) {
        validateDateRangeField([]);
      } else {
        validateDateRangeField([
          new Date(filters.startDate),
          new Date(filters.endDate),
        ]);
        try {
          const response = await ListCustomReportByFilters(
            requestBody,
            paginations
          );
          if (response?.data?.data?.customReport) {
            dispatch(setExportReportButtonDisable({ data: false }));
          } else {
            dispatch(setExportReportButtonDisable({ data: true }));
          }
          if (response?.data?.status === 200) {
            if (response?.data?.data?.customReport?.length > 0) {
              dispatch(setDisableSaveReportFilter(false));
            }
            dispatch(
              setPreviewData({
                ...customReportingState,
                previewData: response?.data,
              })
            );

            dispatch(
              setPaginationData({
                ...customReportingState,
                pagination: response?.data?._pagination,
              })
            );
          }
        } catch (err: any) {
          store.dispatch(
            showToast({
              title: "CustomReport",
              message: err.response?.data?.error?.details[0]?.message,
            })
          );
        }
      }

      dispatch(
        setPageLoadingStatus({
          isPageLoading: false,
        })
      );

      dispatch(
        setShowViewReport({
          ...customReportingState,
          showViewReport: true,
        })
      );
    }
  };

  const handleProgramSubTypeChange = (programSubType: any) => {
    dispatch(setDisableSaveReportFilter(true));
    dispatch(
      setProgramSubType({
        ...customReportingState,
        programSubType: _.map(programSubType, "code"),
      })
    );
    dispatch(
      setProgram({
        ...customReportingState,
        programs: "",
      })
    );
    getPrograms(
      customReportingState.reportDetails?.vendors,
      customReportingState.reportDetails?.accreditations,
      customReportingState.reportDetails?.classifications,
      customReportingState.reportDetails?.certificates,
      customReportingState.reportDetails?.programMode,
      _.map(programSubType?.code, "code").join(","),
      customReportingState.reportDetails?.dateType,
      customReportingState.reportDetails?.startDate,
      customReportingState.reportDetails?.endDate,
      customReportingState.reportDetails?.programs,
      customReportingState.reportDetails?.categories.some(
        (category: CategoryOption) =>
          category.code === CONSTANTS.Programs ||
          category.code === CONSTANTS.accreditationsUnderPrograms
      )
    );


  };

  const customStyles = {
    control: (base: any, state: any) =>
      state.isDisabled
        ? {
          ...base,
          maxHeight: "5rem",
          overflowY: "auto",
          borderColor: "#777979",
          borderRadius: "2px",
          opacity: ".3",
          backgroundColor: "#FFF",
        }
        : {
          ...base,
          maxHeight: "5rem",
          overflowY: "auto",
          borderColor: "#777979",
          borderRadius: "2px",
          outline: state.isFocused && "2px solid #E17509",
          boxShadow: state.isFocused && "0 0 4px 4px #e17509",
        },
    option: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: state.isDisabled ? "white" : "white",
      color: state.isDisabled ? "#c0c0c0" : "#808080",
      cursor: state.isDisabled ? "not-allowed" : "pointer",
    }),
    menu: (base: any) => ({
      ...base,
      zIndex: 9999,
    }),
  };

  let history = useHistory();

  const formatDate = (dates: DateRange | null) => {
    if (!dates?.length) {
      return "";
    }

    return `${dates[0].getFullYear()}-${("0" + (dates[0].getMonth() + 1)).slice(
      -2
    )}-${("0" + dates[0].getDate()).slice(-2)}~${dates[1].getFullYear()}-${(
      "0" +
      (dates[1].getMonth() + 1)
    ).slice(-2)}-${("0" + dates[1].getDate()).slice(-2)}`;
  };

  const dateChangeHanler = (value: string) => {
    dateRangeChange(value);
    setDateRangeErrorClass(false);
  };

  const validateDateRangeField = (value: any) => {
    if (customReportingState.reportDetails?.frequency == "custom_range" && (!customReportingState.reportDetails?.startDate || !customReportingState.reportDetails?.endDate)) {
      setDateRangeErrorClass(true);
      return false;
    } else {
      setDateRangeErrorClass(false);
      return true;
    }
  };

  const validateFrequency = () => {
    const dateType = customReportingState.reportDetails?.dateType;
    const frequency = customReportingState.reportDetails?.frequency;
    if (!_.isEmpty(dateType) && _.isEmpty(frequency)) {
      setFrequencyErrorClass(true);
      return false;
    } else {
      setFrequencyErrorClass(false);
      return true;
    }
  };

  const selectFieldsPage = () => {
    if (!isDisabledSelectReportFields() && validateFrequency() && validateDateRangeField([])) {
      setDateRangeErrorClass(false);
      let id = routeParams?.id;
      if (id) {
        history.push(`/custom-reports/select-fields`, {
          fromSavedReport: true,
          id: id,
          reportName: customReportingState.reportName,
        });
      } else {
        history.push(`/custom-reports/select-fields`);
      }
    }
  };

  const handleMultiSelect = (selected: any, key: string) => {
    dispatch(setDisableSaveReportFilter(true));
    setProgramSearchResult([]);
    const selectedValues: any =
      selected.length === 0
        ? ""
        : selected?.map((obj: any) => obj.code).join(",");

    switch (key) {
      case _.lowerCase(CONSTANTS.Vendors):
        dispatch(
          setVendor({
            ...customReportingState,
            vendors: selectedValues,
          })
        );
        break;
      case _.lowerCase(CONSTANTS.Programs):
        dispatch(
          setProgram({
            ...customReportingState,
            programs: selectedValues,
          })
        );
        break;
      case _.lowerCase(CONSTANTS.Accreditations):
        dispatch(
          setAccreditation({
            ...customReportingState,
            accreditations: selectedValues,
          })
        );
        break;
      case _.lowerCase(CONSTANTS.Classifications):
        dispatch(
          setClassification({
            ...customReportingState,
            classifications: selectedValues,
          })
        );
        break;
      case _.lowerCase(CONSTANTS.Certificates):
        dispatch(
          setCertificate({
            ...customReportingState,
            certificates: selectedValues,
          })
        );
        break;
      default:
        return;
    }
  };

  const handleProgramSubtypeList = async (programType: any, fullData: any) => {
    const programTypeArr = fullData?.filter(function (el: any) {
      return el.Code === programType;
    });

    const programSubTypeDataArr = fullData?.filter(function (el: any) {
      return el?.ParentId === programTypeArr[0]?.ID;
    });
    let programSubTypeArr: any = []

    programSubTypeDataArr?.forEach((obj: any) => {
      programSubTypeArr.push({
        name: obj.Name,
        code: obj.Code,
      });
    })
    dispatch(setProgramSubTypeList(programSubTypeArr))

  }



  const fetchProgramTypes = async () => {
    const programsType = await GetProgramType({});
    dispatch(setProgramTypeSubTypeList(programsType.data.data.programTypes))
    const programTypeArr: any[] = [];
    programsType.data.data.programTypes.forEach((obj: any) => {
      if (obj.ParentId === 0) {
        programTypeArr.push({
          name: obj.Name,
          code: obj.Code,
        });
      }
    });
    dispatch(setProgramTypeList(programTypeArr))

    if (getData?.customReportFilter?.filters?.filters?.programSubType) {
      handleProgramSubtypeList(getData?.customReportFilter?.filters?.filters?.programType, programsType.data.data.programTypes)
    }
  };

  const handleProgramTypeChange = async (selected: any) => {
    dispatch(setDisableSaveReportFilter(true));
    dispatch(updateReportDetail(
      {
        programMode: selected?.code,
        programSubType: []
      }
    ))
    handleProgramSubtypeList(selected?.code, OptionsData.programTypeSubTypeList)
  };

  const handleCategoryChange = async (
    selected: CategoryOption,
    allSelected: CategoryOption[],
    actionMeta?: ActionMeta<any>
  ) => {
    dispatch(setDisableSaveReportFilter(true));
    setDateRangeErrorClass(false);
    setFrequencyErrorClass(false);

    dispatch(
      setProgramSubType({
        ...customReportingState,
        programSubType: [],
      })
    );

    if (allSelected.length === 0) {
      dispatch(
        setFrequency({
          ...customReportingState,
          frequency: "",
          startDate: "",
          endDate: "",
        })
      );
    }

    let selections: string[] = [];

    if (!_.isEmpty(customReportingState.customReportApiReqBody)) {
      allSelected.forEach((item) => {
        selections = [...selections, _.lowerCase(item.code)];
      });
    }


    switch (selected?.code) {
      case CONSTANTS.Vendors:
        const vendorFieldSelected =
          customReportingState.reportDetails?.categories.some(
            (category: CategoryOption) => category.code === CONSTANTS.Vendors
          );
        if (!vendorFieldSelected) {
          await getVendors();

        }
        break;
      case CONSTANTS.Programs:
        const programFieldSelected =
          customReportingState.reportDetails?.categories.some(
            (category: CategoryOption) => category.code === CONSTANTS.Programs
          );
        if (!programFieldSelected) {
          getPrograms(
            customReportingState.reportDetails?.vendors,
            customReportingState.reportDetails?.accreditations,
            customReportingState.reportDetails?.classifications,
            customReportingState.reportDetails?.certificates,
            customReportingState.reportDetails?.programMode,
            customReportingState.reportDetails?.programSubType,
            customReportingState.reportDetails?.dateType,
            customReportingState.reportDetails?.startDate,
            customReportingState.reportDetails?.endDate,
            customReportingState.reportDetails?.programs,
            customReportingState.reportDetails?.categories.some(
              (category: CategoryOption) => category.code === CONSTANTS.Programs
            )
          );
          await fetchProgramTypes();
        }
        break;
      case CONSTANTS.Accreditations:
        const accreditationFieldSelected =
          customReportingState.reportDetails?.categories.some(
            (category: CategoryOption) =>
              category.code === CONSTANTS.Accreditations
          );

        if (!accreditationFieldSelected) {
          await getAccreditations();

        }
        break;
      case CONSTANTS.accreditationsUnderPrograms:
        await getAccreditations();
        await getClassifications(
          customReportingState.reportDetails?.classifications
        );
        await getCertificates();
        await fetchProgramTypes();


        break;

      case CONSTANTS.Classifications:
        const classificationFieldSelected =
          customReportingState.reportDetails?.categories.some(
            (category: CategoryOption) =>
              category.code === CONSTANTS.Classifications
          );

        if (!classificationFieldSelected) {
          await getClassifications(
            customReportingState.reportDetails?.certificates
          );

        }
        break;
      case CONSTANTS.Certificates:
        const certificateFieldSelected =
          customReportingState.reportDetails?.categories.some(
            (category: CategoryOption) =>
              category.code === CONSTANTS.Certificates
          );

        if (!certificateFieldSelected) {
          await getCertificates(
            customReportingState.reportDetails?.accreditations
          );

        }
        break;
      default:


    }
    handleLoaderClose();

    if (
      actionMeta?.action == "remove-value" &&
      actionMeta?.removedValue?.code == CONSTANTS.Accreditations
    ) {
      getCertificates();
    }


    dispatch(
      setSelectedFields({
        ...customReportingState,
        selectedFields: [],
      })
    );
  };

  const handleAddScheduler = () => {
    if (
      validateDateRangeField(dateRangeValue) ||
      customReportingState.reportDetails?.frequency !== "custom_range"
    ) {
      dispatch(
        setCurrentTab({
          PREDEFINED_SCHEDULER: false,
          CUSTOM_SCHEDULER: true,
        })
      );

      dispatch(setShowMyReports(false));

      history.push({
        pathname: Routes.CUSTOM_REPORT_ADD_SCHEDULER,
        state: {
          isSavedFilter: routeParams?.id ? true : false,
        },
      });
    }
  };

  const isSelectedCategory = (category: string): boolean => {
    let found = customReportingState.reportDetails?.categories.find(
      (element: CategoryOption) => element.code === category
    );
    if (found) {
      return true;
    } else {
      return false;
    }
  };

  const handleDateTypeChange = (event: any) => {
    dispatch(setDisableSaveReportFilter(true));
    let dateType = event.target.value;
    setFrequencyErrorClass(false);

    getPrograms(
      customReportingState.reportDetails?.vendors,
      customReportingState.reportDetails?.accreditations,
      customReportingState.reportDetails?.classifications,
      customReportingState.reportDetails?.certificates,
      customReportingState.reportDetails?.programMode,
      customReportingState.reportDetails?.programSubType.join(
        ","
      ),
      dateType,
      customReportingState.reportDetails?.startDate,
      customReportingState.reportDetails?.endDate,
      customReportingState.reportDetails?.programs,
      customReportingState.reportDetails?.categories.some(
        (category: CategoryOption) =>
          category.code === CONSTANTS.Programs ||
          category.code === CONSTANTS.accreditationsUnderPrograms
      )
    );

    dispatch(
      setDateType({
        ...customReportingState,
        dateType: dateType,
      })
    );
  };

  const isCategoryOptionDisabled = (option: any) => {
    const categoryList: string[] =
      customReportingState.reportDetails?.categories.map(
        (category: CategoryOption) => category.code
      );

    switch (option?.code) {
      case CONSTANTS.Vendors: {
        return (
          categoryList.includes(CONSTANTS.Accreditations) ||
          categoryList.includes(CONSTANTS.Certificates) ||
          categoryList.includes(CONSTANTS.Classifications) ||
          categoryList.includes(CONSTANTS.Programs) ||
          categoryList.includes(CONSTANTS.accreditationsUnderPrograms) ||
          categoryList.includes(CONSTANTS.learnerDetail)
        );
      }
      case CONSTANTS.Programs: {
        return (
          categoryList.includes(CONSTANTS.Accreditations) ||
          categoryList.includes(CONSTANTS.Vendors) ||
          categoryList.includes(CONSTANTS.accreditationsUnderPrograms) ||
          categoryList.includes(CONSTANTS.learnerDetail) ||
          categoryList.includes(CONSTANTS.Certificates) ||
          categoryList.includes(CONSTANTS.Classifications)
        );
      }
      case CONSTANTS.Accreditations: {
        return (
          categoryList.includes(CONSTANTS.Vendors) ||
          categoryList.includes(CONSTANTS.Programs) ||
          categoryList.includes(CONSTANTS.accreditationsUnderPrograms) ||
          categoryList.includes(CONSTANTS.learnerDetail)
        );
      }
      case CONSTANTS.Classifications: {
        return (
          categoryList.includes(CONSTANTS.Vendors) ||
          categoryList.includes(CONSTANTS.accreditationsUnderPrograms) ||
          categoryList.includes(CONSTANTS.Programs) ||
          categoryList.includes(CONSTANTS.learnerDetail)
        );
      }
      case CONSTANTS.Certificates: {
        return (
          categoryList.includes(CONSTANTS.Vendors) ||
          categoryList.includes(CONSTANTS.accreditationsUnderPrograms) ||
          categoryList.includes(CONSTANTS.Programs) ||
          categoryList.includes(CONSTANTS.learnerDetail)
        );
      }
      case CONSTANTS.learnerDetail: {
        return (
          categoryList.includes(CONSTANTS.accreditationsUnderPrograms) ||
          categoryList.includes(CONSTANTS.Programs) ||
          categoryList.includes(CONSTANTS.Vendors) ||
          categoryList.includes(CONSTANTS.Certificates) ||
          categoryList.includes(CONSTANTS.Classifications) ||
          categoryList.includes(CONSTANTS.Accreditations)
        );
      }
      case CONSTANTS.accreditationsUnderPrograms: {
        return (
          categoryList.includes(CONSTANTS.learnerDetail) ||
          categoryList.includes(CONSTANTS.Programs) ||
          categoryList.includes(CONSTANTS.Vendors) ||
          categoryList.includes(CONSTANTS.Certificates) ||
          categoryList.includes(CONSTANTS.Classifications) ||
          categoryList.includes(CONSTANTS.Accreditations)
        );
      }
      default:
        break;
    }
  };

  const isDisabledSelectReportFields = () => {
    return customReportingState.reportDetails?.categories?.length === 0;
  };

  const isDisabledViewReport = () => {
    if (_.isEmpty(customReportingState.customReportApiReqBody)) {
      return true;
    }
    if (!hasCategoryChanged()) {
      return (
        convertStringToList(customReportingState.reportDetails?.vendors, ",")
          ?.length === 0 &&
        convertStringToList(customReportingState.reportDetails?.programs, ",")
          ?.length === 0 &&
        convertStringToList(
          customReportingState.reportDetails?.accreditations,
          ","
        )?.length === 0 &&
        convertStringToList(
          customReportingState.reportDetails?.classifications,
          ","
        )?.length === 0 &&
        convertStringToList(
          customReportingState.reportDetails?.certificates,
          ","
        )?.length === 0 &&
        customReportingState.reportDetails?.frequency === ""
      );
    }
    return true;
  };

  const hasCategoryChanged = () => {
    let oldCategories: string[] = categories; //Categories from saved report filter or after doing select report fields > view report
    let newCategories: string[] = []; //categories that are currently selected in the dropdown

    customReportingState.reportDetails?.categories.forEach(
      (item: CategoryOption) => {
        newCategories = [...newCategories, _.lowerCase(item.code)];
      }
    );

    if (listEquals(newCategories, oldCategories)) {
      return false;
    }
    return true;
  };

  const getDateType = () => {
    if (isSelectedCategory(CONSTANTS.Programs)) {
      return dateType.Programs;
    } else if (isSelectedCategory(CONSTANTS.Accreditations)) {
      return dateType.Accreditations;
    } else {
      return [...dateType.Programs, ...dateType.Accreditations];
    }
  };



  return (
    <CustomReportsWrapper>
      <div className="custom-reports-wrapper w-100">
        <div className="container">
          <div className="archive-error-actvity-header">
            <PageHeading heading={t<string>("Custom Reports")}></PageHeading>
          </div>
          <GenerateReportWrapper>
            <div className="row inputs-b">
              <div className="col-lg-12 mb-0 position-relative d-flex flex-column justify-content-between flex-wrap flex-sm-nowrap">
                <div className="generate-report-header row justify-content-between flex-column flex-md-row">
                  <h2
                    className="cp-view-h2 mb-0 pr-3 col-xs-12 col-md-5"
                    data-testid="adminReportHeaderTitle"
                  >
                    {routeParams?.id
                      ? `${t<string>("AdminCustomReport")} -  ${customReportingState.reportName
                      } `
                      : t<string>("AdminCustomReport")}
                  </h2>

                  <div className="col-xs-12 col-md-7 generate-report-header-links-wrapper text-md-right text-left mt-3 mt-md-0">
                    <span className="d-block d-sm-inline-block">
                      <aui-button
                        id="saveReportFilter"
                        buttontitle={t<string>("saveReportFilter")}
                        variant="link-style-arrow"
                        size="medium"
                        aria-label={t<string>("saveReportFilter")}
                        data-testid="saveReportFilter"
                        data-target={
                          routeParams?.id
                            ? "#changeReportFilterModal"
                            : "#saveReportFilterModal"
                        }
                        data-toggle={"modal"}
                        disabled={
                          customReportingState.disableSaveReportFilter ||
                          customReportingState?.isExportReportDisable
                        }
                      ></aui-button>
                    </span>
                    <span className="ml-0 ml-sm-5 d-block d-sm-inline-block mt-3 mt-sm-0">
                      <aui-button
                        id="addScheduler"
                        buttontitle={t<string>("addScheduler")}
                        aria-label={t<string>("addScheduler")}
                        data-testid="addScheduler"
                        variant="link-style-arrow"
                        size="medium"
                        onClick={handleAddScheduler}
                      ></aui-button>
                    </span>
                  </div>
                </div>
              </div>

              {isSelectedCategory(CONSTANTS.accreditationsUnderPrograms) && (
                <div className="col-12 row-margin">
                  <spna
                    className="section-heading"
                    data-testid="accreditation-heading"
                  >
                    {"Accreditations"}
                  </spna>
                </div>
              )}

              <div
                className="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 row-margin"
                data-testid="category_field"
              >
                <Select
                  id="Category"
                  className="multi text-box"
                  classNamePrefix="list"
                  aria-label="selectCategory"
                  value={customReportingState.reportDetails?.categories}
                  placeholder={t<string>("selectCategory")}
                  getOptionValue={(category: any) => {
                    return category.id;
                  }}
                  getOptionLabel={(category: any) => {
                    return category?.label;
                  }}
                  options={comp}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  isOptionDisabled={isCategoryOptionDisabled}
                  onChange={(selected: any, actionMeta: ActionMeta<any>) => {
                    handleCategoryChange(
                      selected[selected.length - 1],
                      selected,
                      actionMeta
                    );

                    // If we remove any category than corresponding state should also get cleared
                    let currentCategories =
                      customReportingState.reportDetails?.categories.map(
                        (item: CategoryOption) => item.code
                      );
                    let currentSelected = selected.map(
                      (item: any) => item.code
                    );

                    if (currentCategories.length > selected.length) {
                      currentCategories.forEach((item: any) => {
                        if (!currentSelected.includes(item)) {
                          if (item === CONSTANTS.accreditationsUnderPrograms) {
                            dispatch(
                              setReportDetails({
                                ...customReportingState,
                                key: _.lowerCase(CONSTANTS.Programs),
                                value: "",
                              })
                            );

                            dispatch(
                              setReportDetails({
                                ...customReportingState,
                                key: _.lowerCase(CONSTANTS.Accreditations),
                                value: "",
                              })
                            );

                            dispatch(
                              setReportDetails({
                                ...customReportingState,
                                key: _.lowerCase(CONSTANTS.Classifications),
                                value: "",
                              })
                            );

                            dispatch(
                              setReportDetails({
                                ...customReportingState,
                                key: _.lowerCase(CONSTANTS.Certificates),
                                value: "",
                              })
                            );
                          } else {
                            dispatch(
                              setReportDetails({
                                ...customReportingState,
                                key: _.lowerCase(item),
                                value: "",
                              })
                            );
                          }

                          // Clear the filter states if we remove program or accreditation under programs
                          if (
                            item === CONSTANTS.Programs ||
                            item === CONSTANTS.accreditationsUnderPrograms
                          ) {

                            dispatch(
                              setDateType({
                                ...customReportingState,
                                dateType: "",
                              })
                            );

                            dispatch(
                              setProgramMode({
                                ...customReportingState,
                                programMode: "",
                              })
                            );

                            dispatch(
                              setProgramSubType({
                                ...customReportingState,
                                programSubType: "",
                              })
                            );

                            dispatch(
                              setFrequency({
                                ...customReportingState,
                                frequency: "",
                                startDate: "",
                                endDate: "",
                              })
                            );
                          }
                        }
                      });
                    }

                    dispatch(
                      setCategory({
                        ...customReportingState,
                        categories: selected,
                      })
                    );
                  }}
                  // @ts-ignore
                  allowSelectAll={false}
                  styles={customStyles}
                />
              </div>
              {customReportingState.reportDetails?.categories.length > 0 &&
                (isSelectedCategory(CONSTANTS.Programs) ||
                  isSelectedCategory(
                    CONSTANTS.accreditationsUnderPrograms
                  )) && (
                  <div
                    className="col-lg-4 col-md-6 col-sm-12 row-margin"
                    data-testid="date_type_field"
                  >
                    <RenderInputSelect
                      elementObject={{
                        id: "selectDateType",
                        name: "selectDateType",
                        placeholder: t<string>("selectDateType"),
                        data: getDateType(),
                        required: true,
                        className: "multi text-box non-react-select-field",
                        selected: customReportingState.reportDetails?.dateType,
                      }}
                      handleData={(e) => handleDateTypeChange(e)}
                    />
                  </div>
                )}

              <div
                className="col-lg-4 col-md-6 col-sm-12 px-10 my-20 row-margin required"
                data-testid="frequency_field"
              >
                <RenderInputSelect
                  elementObject={{
                    id: "selectFrequency",
                    name: "selectFrequency",
                    placeholder: t<string>("selectFrequency"),
                    data: reportFrequency,
                    required: true,
                    className: classNames(
                      "multi text-box non-react-select-field",
                      {
                        "disabled-field":
                          customReportingState.reportDetails?.categories
                            .length === 0,
                        "required-field": frequencyErrorClass,
                      }
                    ),
                    selected: customReportingState.reportDetails?.frequency,
                    disabled:
                      customReportingState.reportDetails?.categories.length ===
                      0,
                  }}
                  handleData={(e) => handleFrequencyChange(e)}
                />
              </div>

              {
                <div
                  className="col-lg-4 col-md-6 col-sm-12 px-10 row-margin"
                  data-testid="dateRange_field"
                >
                  <DateRangePicker
                    appearance="default"
                    placeholder="Select Date Range"
                    format="yyyy-MM-dd"
                    data-testid="daterange"
                    className={`w-100 text-truncate form-control inputs text-box non-react-select-field ${dateRangeErrorClass ? "required-field" : ""
                      }`}
                    style={{
                      color: "Red",
                      display: "block",
                    }}
                    onChange={(dates, ev) => {
                      dateChangeHanler(formatDate(dates));
                    }}
                    disabled={
                      customReportingState.reportDetails?.frequency !==
                      "custom_range"
                    }
                    // @ts-ignore
                    value={
                      customReportingState.reportDetails?.startDate !== "" &&
                      customReportingState.reportDetails?.endDate !== "" &&
                      customReportingState.reportDetails?.frequency ===
                      "custom_range" && [
                        new Date(customReportingState.reportDetails?.startDate),
                        new Date(customReportingState.reportDetails?.endDate),
                      ]
                    }
                    // @ts-ignore
                    placement={
                      customReportingState.reportDetails?.categories.some(
                        (category: CategoryOption) =>
                          category.code === CONSTANTS.Programs ||
                          category.code ===
                          CONSTANTS.accreditationsUnderPrograms
                      )
                        ? "bottomStart"
                        : "bottomEnd"
                    }
                  />
                </div>
              }

              {customReportingState.reportDetails?.categories.length > 0 &&
                customReportingState.reportDetails?.categories.map(
                  (item: CategoryOption) => {
                    return item.code === CONSTANTS.Vendors ? (
                      <div
                        key={item.id}
                        className="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 row-margin"
                        data-testid="custom-field"
                      >
                        <Select
                          id={item.code}
                          className="multi text-box"
                          classNamePrefix="list"
                          aria-label="multi-select"
                          value={filterSelectedMultiSelect(
                            OptionsData?.vendorList,
                            convertStringToList(
                              customReportingState.reportDetails?.vendors,
                              ","
                            ),
                            CONSTANTS.Vendors
                          )}
                          placeholder={`All Vendor Selected`}
                          getOptionValue={(vendor: any) => {
                            return vendor.id;
                          }}
                          getOptionLabel={(vendor: any) => {
                            return vendor.name;
                          }}
                          options={OptionsData?.vendorList}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={true}
                          onChange={(selected: any) => {
                            handleMultiSelect(
                              selected,
                              _.lowerCase(CONSTANTS.Vendors)
                            );
                          }}
                          // @ts-ignore
                          allowSelectAll={true}
                          styles={customStyles}
                        />
                      </div>
                    ) : (
                      item.code === CONSTANTS.Programs && (
                        <>
                          <div
                            className="col-lg-4 col-md-6 col-sm-12 px-10 my-20 row-margin"
                            data-testid="program_mode_field"
                          >
                            <Select
                              id={"selectProgramMode"}
                              className="multi text-box non-react-select-field"
                              classNamePrefix="list"
                              aria-label="selectProgramMode"
                              value={
                                _.filter(
                                  OptionsData?.programTypeList, function (o) {
                                    return o?.code === customReportingState.reportDetails?.programMode
                                  })
                              }
                              placeholder={t<string>("selectProgramMode")}
                              getOptionValue={(type: any) => {
                                return type.code;
                              }}
                              getOptionLabel={(type: any) => {
                                return type.name;
                              }}
                              options={OptionsData?.programTypeList}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={true}
                              onChange={(selected) => {
                                handleProgramTypeChange(selected)
                              }}
                              styles={customStyles}
                              isClearable
                              escapeClearsValue
                            />
                          </div>

                          <div
                            className="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 row-margin"
                            data-testid="program_subtype_field "
                          >
                            <Select
                              id={"selectProgramSubtype"}
                              className="multi text-box"
                              classNamePrefix="list"
                              aria-label="selectProgramSubtype"
                              value={
                                OptionsData?.programSubTypeList?.filter((o) => {
                                  return customReportingState.reportDetails?.programSubType.includes(o?.code)
                                })
                              }
                              placeholder={t<string>("selectProgramSubtype")}
                              getOptionValue={(subType: any) => {
                                return subType.code;
                              }}
                              getOptionLabel={(subType: any) => {
                                return subType.name;
                              }}
                              isMulti
                              options={OptionsData?.programSubTypeList}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={true}
                              onChange={(selected) => {
                                handleProgramSubTypeChange(selected);
                              }}
                              isDisabled={
                                customReportingState.reportDetails?.programMode
                                  ? false
                                  : true
                              }
                              styles={customStyles}
                              isClearable
                              escapeClearsValue
                            />
                          </div>

                          <div
                            key={item.id}
                            className="col-md-8 col-sm-12 px-10 row-margin"
                            data-testid="custom-field"
                          >
                            <AsyncPaginate
                              id={item.code}
                              className="multi text-box"
                              classNamePrefix="list"
                              aria-label="selectPrograms"
                              value={filterSelectedMultiSelect(
                                OptionsData?.programList,
                                convertStringToList(
                                  customReportingState.reportDetails?.programs,
                                  ","
                                ),
                                CONSTANTS.Programs
                              )}
                              loadOptions={programLoadOptions}
                              getOptionValue={(program: any) => {
                                return program.code;
                              }}
                              getOptionLabel={(program: any) => {
                                return `${program.name} (${program.code})`;
                              }}
                              onChange={(selected: any) => {
                                handleMultiSelect(
                                  selected,
                                  _.lowerCase(CONSTANTS.Programs)
                                );
                              }}
                              isSearchable={true}
                              placeholder="All Program Selected"
                              additional={{
                                page: 1,
                              }}
                              isMulti
                              closeMenuOnSelect={false}
                              styles={customStyles}
                              debounceTimeout={500}
                              shouldLoadMore={shouldLoadMore}
                              cacheUniqs={[
                                customReportingState.reportDetails
                                  ?.accreditations,
                                customReportingState.reportDetails
                                  ?.certificates,
                                customReportingState.reportDetails
                                  ?.classifications,
                                customReportingState.reportDetails?.frequency,
                                customReportingState.reportDetails?.startDate,
                                customReportingState.reportDetails?.dateType,
                                customReportingState.reportDetails?.programMode,
                                customReportingState.reportDetails
                                  ?.programSubType,
                              ]}
                            />
                          </div>
                        </>
                      )
                    );
                  }
                )}

              {isSelectedCategory(CONSTANTS.Accreditations) && (
                <div
                  key={CONSTANTS.Accreditations}
                  className="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 row-margin"
                  data-testid="custom-field"
                >
                  <Select
                    id={CONSTANTS.Accreditations}
                    name="selectAccreditation"
                    className="multi text-box"
                    classNamePrefix="list"
                    aria-label="selectAccreditation"
                    value={filterSelectedMultiSelect(
                      OptionsData?.accreditationList,
                      convertStringToList(
                        customReportingState.reportDetails?.accreditations,
                        ","
                      ),
                      CONSTANTS.Accreditations
                    )}
                    placeholder={`All Accreditation Selected`}
                    getOptionValue={(accreditation: any) => {
                      return accreditation.id;
                    }}
                    getOptionLabel={(accreditation: any) => {
                      return accreditation.code;
                    }}
                    options={OptionsData?.accreditationList}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={true}
                    onChange={(selected: any) => {
                      handleMultiSelect(
                        selected,
                        _.lowerCase(CONSTANTS.Accreditations)
                      );

                      getPrograms(
                        customReportingState.reportDetails?.vendors,
                        convertListToString(
                          selected.map((item: any) => item.code)
                        ),
                        customReportingState.reportDetails?.classifications,
                        customReportingState.reportDetails?.certificates,
                        customReportingState.reportDetails?.programMode,
                        customReportingState.reportDetails?.programSubType.join(
                          ","
                        ),
                        customReportingState.reportDetails?.dateType,
                        customReportingState.reportDetails?.startDate,
                        customReportingState.reportDetails?.endDate,
                        customReportingState.reportDetails?.programs,
                        customReportingState.reportDetails?.categories.some(
                          (category: CategoryOption) =>
                            category.code === CONSTANTS.Programs ||
                            category.code ===
                            CONSTANTS.accreditationsUnderPrograms
                        )
                      );
                      getCertificates(
                        convertListToString(
                          selected.map((item: any) => item.code)
                        ),
                        [],
                        customReportingState.reportDetails?.categories.some(
                          (category: CategoryOption) =>
                            category.code === CONSTANTS.Certificates
                        )
                      );
                    }}
                    // @ts-ignore
                    allowSelectAll={true}
                    styles={customStyles}
                  />
                </div>
              )}
              {isSelectedCategory(CONSTANTS.Certificates) && (
                <div
                  key={CONSTANTS.Certificates}
                  className="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 row-margin"
                  data-testid="custom-field"
                >
                  <Select
                    id={CONSTANTS.Certificates}
                    className="multi text-box"
                    classNamePrefix="list"
                    aria-label="selectCertificates"
                    value={filterSelectedMultiSelect(
                      OptionsData?.certificateList,
                      convertStringToList(
                        customReportingState.reportDetails?.certificates,
                        ","
                      ),
                      CONSTANTS.Certificates
                    )}
                    placeholder={`All Certificate Selected`}
                    getOptionValue={(certificate: any) => {
                      return certificate.id;
                    }}
                    getOptionLabel={(certificate: any) => {
                      return certificate.name;
                    }}
                    options={OptionsData?.certificateList}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={true}
                    onChange={(selected: any) => {
                      handleMultiSelect(
                        selected,
                        _.lowerCase(CONSTANTS.Certificates)
                      );

                      getPrograms(
                        customReportingState.reportDetails?.vendors,
                        customReportingState.reportDetails?.accreditations,
                        customReportingState.reportDetails?.classifications,
                        convertListToString(
                          selected.map((item: any) => item.code)
                        ),
                        customReportingState.reportDetails?.programMode,
                        customReportingState.reportDetails?.programSubType.join(
                          ","
                        ),
                        customReportingState.reportDetails?.dateType,
                        customReportingState.reportDetails?.startDate,
                        customReportingState.reportDetails?.endDate,
                        customReportingState.reportDetails?.programs,
                        customReportingState.reportDetails?.categories.some(
                          (category: CategoryOption) =>
                            category.code === CONSTANTS.Programs
                        )
                      );

                      getClassifications(
                        convertListToString(
                          selected.map((item: any) => item.code)
                        ),
                        customReportingState.reportDetails?.categories.some(
                          (category: CategoryOption) =>
                            category.code === CONSTANTS.Classifications ||
                            category.code ===
                            CONSTANTS.accreditationsUnderPrograms
                        )
                      );
                    }}
                    // @ts-ignore
                    allowSelectAll={true}
                    styles={customStyles}
                  />
                </div>
              )}
              {isSelectedCategory(CONSTANTS.Classifications) && (
                <div
                  key={CONSTANTS.Classifications}
                  className="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 row-margin"
                  data-testid="custom-field"
                >
                  <Select
                    id={CONSTANTS.Classifications}
                    className="multi text-box"
                    classNamePrefix="list"
                    aria-label="selectClassifications"
                    value={filterSelectedMultiSelect(
                      OptionsData?.classificationList,
                      convertStringToList(
                        customReportingState.reportDetails?.classifications,
                        ","
                      ),
                      CONSTANTS.Classifications
                    )}
                    placeholder={`All Classification Selected`}
                    getOptionValue={(classification: any) => {
                      return classification.id;
                    }}
                    getOptionLabel={(classification: any) => {
                      return classification.name;
                    }}
                    options={OptionsData?.classificationList}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={true}
                    onChange={(selected: any) => {
                      handleMultiSelect(
                        selected,
                        _.lowerCase(CONSTANTS.Classifications)
                      );

                      getPrograms(
                        customReportingState.reportDetails?.vendors,
                        customReportingState.reportDetails?.accreditations,
                        convertListToString(
                          selected.map((item: any) => item.code)
                        ),
                        customReportingState.reportDetails?.certificates,
                        customReportingState.reportDetails?.programMode,
                        customReportingState.reportDetails?.programSubType.join(
                          ","
                        ),
                        customReportingState.reportDetails?.dateType,
                        customReportingState.reportDetails?.startDate,
                        customReportingState.reportDetails?.endDate,
                        customReportingState.reportDetails?.programs,
                        customReportingState.reportDetails?.categories.some(
                          (category: CategoryOption) =>
                            category.code === CONSTANTS.Programs
                        )
                      );
                    }}
                    // @ts-ignore
                    allowSelectAll={true}
                    styles={customStyles}
                  />
                </div>
              )}

              {isSelectedCategory(CONSTANTS.accreditationsUnderPrograms) && (
                <React.Fragment>
                  <div
                    key="accreditations"
                    className="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 row-margin"
                    data-testid="custom-field"
                  >
                    <Select
                      id="accreditations"
                      name="selectAccreditation"
                      className="multi text-box"
                      classNamePrefix="list"
                      aria-label="selectAccreditation"
                      value={filterSelectedMultiSelect(
                        OptionsData?.accreditationList,
                        convertStringToList(
                          customReportingState.reportDetails?.accreditations,
                          ","
                        ),
                        CONSTANTS.Accreditations
                      )}
                      placeholder="All Accreditation Selected"
                      getOptionValue={(accreditation: any) => {
                        return accreditation.id;
                      }}
                      getOptionLabel={(accreditation: any) => {
                        return accreditation.code;
                      }}
                      options={OptionsData?.accreditationList}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={true}
                      onChange={(selected: any) => {
                        handleMultiSelect(
                          selected,
                          _.lowerCase(CONSTANTS.Accreditations)
                        );

                        getPrograms(
                          customReportingState.reportDetails?.vendors,
                          convertListToString(
                            selected.map((item: any) => item.code)
                          ),
                          customReportingState.reportDetails?.classifications,
                          customReportingState.reportDetails?.certificates,
                          customReportingState.reportDetails?.programMode,
                          customReportingState.reportDetails?.programSubType.join(
                            ","
                          ),
                          customReportingState.reportDetails?.dateType,
                          customReportingState.reportDetails?.startDate,
                          customReportingState.reportDetails?.endDate,
                          customReportingState.reportDetails?.programs,
                          customReportingState.reportDetails?.categories.some(
                            (category: CategoryOption) =>
                              category.code === CONSTANTS.Programs ||
                              category.code ===
                              CONSTANTS.accreditationsUnderPrograms
                          )
                        );
                        getCertificates(
                          convertListToString(
                            selected.map((item: any) => item.code)
                          ),
                          [],
                          customReportingState.reportDetails?.categories.some(
                            (category: CategoryOption) =>
                              category.code ===
                              CONSTANTS.accreditationsUnderPrograms
                          )
                        );
                      }}
                      // @ts-ignore
                      allowSelectAll={true}
                      styles={customStyles}
                    />
                  </div>

                  <div
                    key="certificates"
                    className="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 row-margin"
                    data-testid="custom-field"
                  >
                    <Select
                      id="certificates"
                      className="multi text-box"
                      classNamePrefix="list"
                      aria-label="selectCertificates"
                      value={filterSelectedMultiSelect(
                        OptionsData?.certificateList,
                        convertStringToList(
                          customReportingState.reportDetails?.certificates,
                          ","
                        ),
                        CONSTANTS.Certificates
                      )}
                      placeholder="All Certificate Selected"
                      getOptionValue={(certificate: any) => {
                        return certificate.id;
                      }}
                      getOptionLabel={(certificate: any) => {
                        return certificate.name;
                      }}
                      options={OptionsData?.certificateList}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={true}
                      onChange={(selected: any) => {
                        handleMultiSelect(
                          selected,
                          _.lowerCase(CONSTANTS.Certificates)
                        );

                        getPrograms(
                          customReportingState.reportDetails?.vendors,
                          customReportingState.reportDetails?.accreditations,
                          customReportingState.reportDetails?.classifications,
                          convertListToString(
                            selected.map((item: any) => item.code)
                          ),
                          customReportingState.reportDetails?.programMode,
                          customReportingState.reportDetails?.programSubType.join(
                            ","
                          ),
                          customReportingState.reportDetails?.dateType,
                          customReportingState.reportDetails?.startDate,
                          customReportingState.reportDetails?.endDate,
                          customReportingState.reportDetails?.programs,
                          customReportingState.reportDetails?.categories.some(
                            (category: CategoryOption) =>
                              category.code === CONSTANTS.Programs
                          )
                        );

                        getClassifications(
                          convertListToString(
                            selected.map((item: any) => item.code)
                          ),
                          customReportingState.reportDetails?.categories.some(
                            (category: CategoryOption) =>
                              category.code === CONSTANTS.Classifications ||
                              category.code ===
                              CONSTANTS.accreditationsUnderPrograms
                          )
                        );
                      }}
                      // @ts-ignore
                      allowSelectAll={true}
                      styles={customStyles}
                    />
                  </div>

                  <div
                    key="classifications"
                    className="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 row-margin"
                    data-testid="custom-field"
                  >
                    <Select
                      id="classifications"
                      className="multi text-box"
                      classNamePrefix="list"
                      aria-label="selectClassifications"
                      value={filterSelectedMultiSelect(
                        OptionsData?.classificationList,
                        convertStringToList(
                          customReportingState.reportDetails?.classifications,
                          ","
                        ),
                        CONSTANTS.Classifications
                      )}
                      placeholder="All Classification Selected"
                      getOptionValue={(classification: any) => {
                        return classification.id;
                      }}
                      getOptionLabel={(classification: any) => {
                        return classification.name;
                      }}
                      options={OptionsData?.classificationList}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={true}
                      onChange={(selected: any) => {
                        handleMultiSelect(
                          selected,
                          _.lowerCase(CONSTANTS.Classifications)
                        );

                        getPrograms(
                          customReportingState.reportDetails?.vendors,
                          customReportingState.reportDetails?.accreditations,
                          convertListToString(
                            selected.map((item: any) => item.code)
                          ),
                          customReportingState.reportDetails?.certificates,
                          customReportingState.reportDetails?.programMode,
                          customReportingState.reportDetails?.programSubType.join(
                            ","
                          ),
                          customReportingState.reportDetails?.dateType,
                          customReportingState.reportDetails?.startDate,
                          customReportingState.reportDetails?.endDate,
                          customReportingState.reportDetails?.programs,
                          customReportingState.reportDetails?.categories.some(
                            (category: CategoryOption) =>
                              category.code === CONSTANTS.Programs
                          )
                        );
                      }}
                      // @ts-ignore
                      allowSelectAll={true}
                      styles={customStyles}
                    />
                  </div>

                  <div className="bdr-btm w-90"></div>
                  <div className="col-lg-12 col-md-12 col-sm-12 ">
                    <span className="section-heading">{"Programs"}</span>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 col-sm-12 px-10 my-20 row-margin"
                    data-testid="program_mode_field"
                  >
                    <Select
                      id={"selectProgramMode"}
                      className="multi text-box non-react-select-field"
                      classNamePrefix="list"
                      aria-label="selectProgramMode"
                      value={
                        _.filter(
                          OptionsData?.programTypeList, function (o) {
                            return o?.code === customReportingState.reportDetails?.programMode
                          })
                      }
                      placeholder={t<string>("selectProgramMode")}
                      getOptionValue={(type: any) => {
                        return type.code;
                      }}
                      getOptionLabel={(type: any) => {
                        return type.name;
                      }}
                      options={OptionsData?.programTypeList}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={true}
                      onChange={(selected) => {
                        handleProgramTypeChange(selected);
                      }}
                      styles={customStyles}
                      isClearable
                      escapeClearsValue
                    />
                  </div>

                  <div
                    className="col-lg-4 col-md-6 col-sm-12 px-10 my-20 row-margin"
                    data-testid="program_subtype_field"
                  >
                    <Select
                      id={"selectProgramSubtype"}
                      className="multi text-box "
                      classNamePrefix="list"
                      aria-label="selectProgramSubtype"
                      value={
                        OptionsData?.programSubTypeList?.filter((o) => {
                          return customReportingState.reportDetails?.programSubType.includes(o?.code)
                        })
                      }
                      placeholder={t<string>("selectProgramSubtype")}
                      getOptionValue={(subType: any) => {
                        return subType.code;
                      }}
                      getOptionLabel={(subType: any) => {
                        return subType.name;
                      }}
                      isMulti
                      options={OptionsData?.programSubTypeList}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={true}
                      onChange={(selected) => {
                        handleProgramSubTypeChange(selected);
                      }}
                      isDisabled={
                        customReportingState.reportDetails?.programMode
                          ? false
                          : true
                      }
                      styles={customStyles}
                      isClearable
                      escapeClearsValue
                    />
                  </div>

                  <div
                    key={CONSTANTS.accreditationsUnderPrograms}
                    className="col-md-8 col-sm-12 px-10 row-margin"
                    data-testid="custom-field"
                  >
                    <AsyncPaginate
                      id={CONSTANTS.accreditationsUnderPrograms}
                      className="multi text-box"
                      classNamePrefix="list"
                      aria-label="selectPrograms"
                      value={filterSelectedMultiSelect(
                        OptionsData?.programList,
                        convertStringToList(
                          customReportingState.reportDetails?.programs,
                          ","
                        ),
                        CONSTANTS.Programs
                      )}
                      loadOptions={programLoadOptions}
                      getOptionValue={(program: any) => {
                        return program.code;
                      }}
                      getOptionLabel={(program: any) => {
                        return `${program.name} (${program.code})`;
                      }}
                      onChange={(selected: any) => {
                        handleMultiSelect(
                          selected,
                          _.lowerCase(CONSTANTS.Programs)
                        );
                      }}
                      isSearchable={true}
                      placeholder={`All Program Selected`}
                      additional={{
                        page: 1,
                      }}
                      isMulti
                      closeMenuOnSelect={false}
                      styles={customStyles}
                      debounceTimeout={500}
                      shouldLoadMore={shouldLoadMore}
                      cacheUniqs={[
                        customReportingState.reportDetails?.accreditations,
                        customReportingState.reportDetails?.certificates,
                        customReportingState.reportDetails?.classifications,
                        customReportingState.reportDetails?.frequency,
                        customReportingState.reportDetails?.startDate,
                        customReportingState.reportDetails?.dateType,
                        customReportingState.reportDetails?.programMode,
                        customReportingState.reportDetails?.programSubType,
                      ]}
                    />
                  </div>
                </React.Fragment>
              )}

              <div className="bdr-btm w-90"></div>

              <div className="button-div-block">
                <div className="aui-btn-wrapper">
                  <aui-button
                    buttonclass="w-100"
                    data-testid="btnSelectFields"
                    variant="secondary"
                    buttontitle={t<string>("selectReportFields")}
                    onClick={selectFieldsPage}
                    size="medium"
                    disabled={isDisabledSelectReportFields()}
                  ></aui-button>
                </div>
                <div className="aui-btn-wrapper">
                  <aui-button
                    buttonclass="w-100"
                    data-testid="btnViewReport"
                    variant="primary"
                    buttontitle={t<string>("viewReport")}
                    onClick={handleViewReport}
                    size="medium"
                    disabled={isDisabledViewReport()}
                  ></aui-button>
                </div>
              </div>
            </div>
            <ModalPopupComp
              id={"saveReportFilterModal"}
              childrenHeader={
                <ModalHeader
                  component="CustomReport"
                  setSavedReportName={setSavedReportName}
                  setShowError={setShowError}
                  setReportNameErrorClass={setReportNameErrorClass}
                >
                  Save Report Filter
                </ModalHeader>
              }
              childrenBody={
                <SaveReportBody
                  validateDateRange={validateDateRangeField}
                  setSavedReportName={setSavedReportName}
                  setShowError={setShowError}
                  setReportNameErrorClass={setReportNameErrorClass}
                  savedReportName={savedReportName}
                  reportNameErrorClass={reportNameErrorClass}
                  showError={showError}
                />
              }
              modalSize="modal-sm"
              childrenBodyClass="save-report-modal-body"
              popupDiscardFunc={modalPopupProps.popupDiscardFunc}
            />

            <ModalPopupComp
              id={"changeReportFilterModal"}
              childrenHeader={
                <ModalHeader
                  component="CustomReport"
                  saveReportNameRef={saveReportNameRef}
                  setShowError={setShowError}
                  setReportNameErrorClass={setReportNameErrorClass}
                >
                  Save Report Filter
                </ModalHeader>
              }
              childrenBody={
                <ChangeReportModalBody
                  validateDateRangeField={validateDateRangeField}
                  reportName={customReportingState.reportName}
                  saveReportNameRef={saveReportNameRef}
                  reportNameErrorClass={reportNameErrorClass}
                  showError={showError}
                  setShowError={setShowError}
                  setReportNameErrorClass={setReportNameErrorClass}
                />
              }
              modalSize="modal-sm"
              childrenBodyClass="save-report-modal-body"
              popupDiscardFunc={modalPopupProps.popupDiscardFunc}
            />
          </GenerateReportWrapper>
        </div>
      </div>


      <ViewReport
        data={customReportingState?.previewData?.data?.customReport}
        columns={customReportingState?.tableColumns}
      />
    </CustomReportsWrapper>
  );
};

export default AdminCustomReport;
