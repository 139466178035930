import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

interface Props {
  elementObject: any;
  handleData: any;
}

export const RenderAuiTextAreaField = ({
  elementObject,
  handleData,
}: Props) => {
  const [inputValue, setInputValue] = useState("");
  const stripHtml = (str: any) => {
    setInputValue(str.replace(/(<([^>]+)>)/gi, ""));
  };
  useEffect(() => {
    setInputValue(elementObject.value);
  }, [elementObject.value]);
  useEffect(() => {
    const dynamicIds = ["secondaryEmail", "email"];
    document.querySelectorAll("aui-textarea").forEach((input) => {
      const textareaId = input?.textareaid;
      if (dynamicIds.includes(textareaId)) {
        input?.addEventListener("auiTextareaChange", (event) =>
          handleData(textareaId, event)
        );
      }
    });
    return () => {
      document.querySelectorAll("aui-textarea").forEach((input) => {
        const textareaId = input?.textareaid;
        if (dynamicIds.includes(textareaId)) {
          input?.removeEventListener("inputChange", (event) =>
            handleChange(textareaId, event)
          );
        }
      });
    };
  });
  return (
    <aui-textarea
      textareaid={elementObject.id}
      label={elementObject.label}
      labelgrid={elementObject.labelgrid}
      textareagrid={elementObject.textareagrid}
      data-testid={elementObject.id}
      name={elementObject.name}
      errorstate={elementObject.errorstate}
      errormessage={elementObject.errormessage}
      value={
        typeof inputValue === "string"
          ? inputValue.replace(/(<([^>]+)>)/gi, "")
          : inputValue
      }
      onChange={(e) => stripHtml(e.target.value)}
      required={elementObject.required}
      placeholder={elementObject.placeholder}
      ref={elementObject.ref}
    />
  );
};

RenderAuiTextAreaField.propTypes = {
  elementObject: PropTypes.object,
  handleData: PropTypes.func,
};

RenderAuiTextAreaField.defaultProps = {
  elementObject: { id: "", name: "", value: "", required: false },
  handleData: () => null,
};

export default RenderAuiTextAreaField;
