import React from "react";
import { ModalPopupWrapper } from "./Styled";
import { useTranslation } from "react-i18next";

interface ModalProps {
  data?: any;
  childrenHeader?: any;
  childHeaderClass?: any;
  childrenBody?: any;
  childrenBodyClass?: any;
  displayButtons?: boolean;
  modalSize?: any;
  modalParent?: any;
  id?: string;
  backdrop?: "static" | Boolean;
  component?: string;
  popupDiscardFunc?: React.MouseEventHandler<HTMLElement>;
}

const ModalPopupComp: React.FC<ModalProps> = ({
  popupDiscardFunc,
  id,
  childrenHeader,
  childHeaderClass,
  childrenBody,
  childrenBodyClass,
  displayButtons = false,
  modalSize,
  modalParent,
  backdrop = "static",
  component = "",
}: ModalProps) => {
  const { t } = useTranslation();

  const isLogComponent = [
    "AuditLog",
    "ActivityLog",
    "BatchLog",
    "VendorLog",
    "ReportLog",
  ].includes(component);

  return (
    <ModalPopupWrapper>
      <aui-modal
        class="modal fade auimodal"
        data-testid={id || "modalWrapper"}
        id={id || "modal_backbtn"}
        tabIndex={-1}
        aria-labelledby={id}
        aria-hidden="true"
        modalsize="auimodal-sm"
        heading="true"
        headtag="h5"
        bordercolors="#e3e3e3"
        closebuttonlink="true"
      >
        <div
          className={
            "modal-dialog " + (isLogComponent ? " modal-dialog-logs" : "")
          }
        >
          <div className={"modal-content d-block " + childHeaderClass}>
            {childrenHeader}
            <div>
              <aui-modal-content>
                <div className="text-center d-flex flex-column">
                  <div className="d-flex">
                    <div className="w-100">
                      <div
                        className={
                          "p-0 " +
                          (isLogComponent ? "modal-body-logs" : "modal-body")
                        }
                      >
                        <div className={"text-center " + childrenBodyClass}>
                          {childrenBody}
                          {displayButtons && (
                            <div className="d-flex justify-content-end auimodals-buttons">
                              <div className="ml-3">
                                <aui-button
                                  variant="secondary"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                  buttontitle={t<string>("no")}
                                  size="medium"
                                  data-testid="modalButtonNo"
                                ></aui-button>
                              </div>
                              <div className="ml-3">
                                <aui-button
                                  variant="primary"
                                  buttontitle={t<string>("yes")}
                                  data-dismiss="modal"
                                  onClick={popupDiscardFunc}
                                  data-testid="modalButtonYes"
                                  size="medium"
                                ></aui-button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </aui-modal-content>
            </div>
          </div>
        </div>
      </aui-modal>
    </ModalPopupWrapper>
  );
};

export default ModalPopupComp;
