import React, { ElementType, ReactNode } from "react";
import classNames from "classnames";

interface ModalHeaderProps {
  as?: ElementType;
  children?: ReactNode;
  className?: any;
  showCloseButton?: boolean;
  component?: string;
  setShowError?: any;
  setRevisionName?: any;
  setReportNameErrorClass?: any;
  setSavedReportName?: any;
  saveReportNameRef?: any;
  closePopUpFn?: any;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  as: Tag = "h6",
  children = "Changes Description",
  className,
  showCloseButton = true,
  component = "",
  setRevisionName,
  setShowError,
  setReportNameErrorClass,
  setSavedReportName,
  saveReportNameRef,
  closePopUpFn,
}: ModalHeaderProps) => {
  const isLogComponent: boolean = [
    "AuditLog",
    "ActivityLog",
    "BatchLog",
    "VendorLog",
    "ReportLog",
    "CustomReport",
    "Certificate",
    "ReportFilter",
  ].includes(component);

  return (
    <>
      <div className="modal-header p-0" data-testid="popup_header">
        <aui-modal-headtag
          modalsize="auimodal-sm"
          heading="true"
          headtag="h5"
          data-testid="header-title"
          bordercolors="#e3e3e3"
          closebuttonlink="true"
        >
          {children}
        </aui-modal-headtag>
      </div>

      {showCloseButton && (
        <button
          type="button"
          className="close aui-modalclose position-absolute p-1 aui-modalclosecls"
          data-dismiss="modal"
          onClick={() => {
            if (setReportNameErrorClass) {
              setReportNameErrorClass(false);
            }
            if (setShowError) {
              setShowError("");
            }
            if (setRevisionName) {
              setRevisionName("");
            }
            if (setSavedReportName) {
              setSavedReportName("");
            }
            if (closePopUpFn) {
              closePopUpFn();
            }

            if (saveReportNameRef?.current) {
              saveReportNameRef.current.value = "";
            }
          }}
          aria-label="Close"
          ref={saveReportNameRef}
        >
          <aui-icon aria-hidden="true" icon="closeIcon" />
        </button>
      )}
    </>
  );
};
