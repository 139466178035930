import React, { useState, FunctionComponent, Suspense } from "react";
import AdminHeader from "components/AdminHeader";
import AdminFooter from "components/AdminFooter";
import AdminLeftPanel from "components/AdminLeftPanel";
import Loader from "components/Loader";
import Toast from "components/Toast";

import AdminWrapper from "./styled";
import { useEffect } from "react";
import classNames from "classnames";
import ModalPopupComp from "components/ModalPopup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  clearReportDetails,
  listCustomReportFiltersApiCall,
  setInitialSavedFilterData,
  setSavedReportData,
} from "pages/Report/CustomReport/CustomReportSlice";
import { DeleteCustomReportFilter } from "services/api/report.api";
import { handleAPIError } from "common/ErrorHandler";
import { Routes } from "common/constants/routes";
import { useHistory } from "react-router-dom";
import { setActiveFilter, setCount, setDeleteReport, setSavedFilterflag, setShowMyReports, setShowPopup } from "components/AdminLeftPanel/LeftPanelSlice";

export interface AdminLayoutProps {}

const MainComponent = React.lazy(() => import("./mainContentFragment"));

export const AdminLayout: FunctionComponent<AdminLayoutProps> = (props) => {
  const [toggleNavBar, setNavbar] = useState(false);
  const [toggleMainContent, setMainContent] = useState(false);
  const [showSidePanel, setSidePanel] = useState(true);
  const [popupData, setPopupData] = useState<any>();
  const leftPanelState = useSelector((state: any) => {
    return state.adminLeftPanel;
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const customReportingState = useSelector((state: any) => {
    return state.customReport;
  });

  const deleteReport = async () => {
    try {
      await DeleteCustomReportFilter({ ids: [leftPanelState.deleteReport.id] });
    } catch (err) {
      handleAPIError(err, history, dispatch);
    }
    dispatch(setSavedReportData(null));
    dispatch(setInitialSavedFilterData({}))
    dispatch(setActiveFilter(null));
    dispatch(clearReportDetails());
    history.replace("/admin/customReport");
    if (customReportingState.lastItemDeleted) {
      dispatch(setShowMyReports(false));
    }
    dispatch(setSavedFilterflag(true));
    dispatch(
      setDeleteReport({
        id: "",
        activeId: "",
        searchFilter: "",
      })
    );
  };

  const navigateToSavedFilter = ()=>{
    history.push(Routes.CUSTOM_REPORTS_HOME_PAGE + "/" + popupData?.item?.id, { isSavedFilter: true })
    dispatch(setActiveFilter(popupData?.item))
    dispatch(setShowPopup(false))
    dispatch(setCount({})) 
  }

  const toggle = () => {
    setNavbar((isToggleNavBar) => !isToggleNavBar);
    setMainContent((isToggleMainContent) => !isToggleMainContent);
  };

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 991;

  const PopupBody = () => {
    return (
      <p
        className="aui-confirmationtext text-left mb-4 pb-2"
        data-testid="confirmMsg"
      >
        Are you sure you want to delete this Report Filter?
      </p>
    );
  };

  const WarningBody = () => {
    return (
      <p
        className="aui-confirmationtext text-left mb-4 pb-2"
        data-testid="confirmMsg"
      >
        Are you sure you want to discard the changes?
      </p>
    );
  };

  return (
    <AdminWrapper>
      <div className="left-panel">
        <div
          className={classNames("sidenav", {
            "left-nav-bar-show": toggleNavBar && isMobile,
            "left-nav-bar": !toggleNavBar || !isMobile,
          })}
          style={showSidePanel ? {} : { width: "4.9rem" }}
        >
          <AdminLeftPanel
            toggle={toggle}
            {...props}
            setSidePanel={setSidePanel}
            showSidePanel={showSidePanel}
            setPopupData={setPopupData}
          />
        </div>

        <div
          className={classNames("d-flex flex-column main w-100 ", {
            "main-content-hide": toggleMainContent,
            "main-content": !toggleMainContent,
          })}
          style={
            showSidePanel
              ? { marginLeft: "20%", overflow: "hidden" }
              : { marginLeft: "6%", overflow: "hidden" }
          }
        >
          <AdminHeader
            toggle={toggle}
            {...props}
            setSidePanel={setSidePanel}
            showSidePanel={showSidePanel}
          />
          {/* <ScrollToTop /> */}
          <Toast />

          <main className="d-flex flex-column flex-grow-1" id="maincontent">
            <Suspense
              fallback={
                <div
                  className="aui-loader"
                  role="alert"
                  aria-live="assertive"
                  aria-label="Page is loading"
                />
              }
            >
              <MainComponent {...props} />
            </Suspense>
          </main>

          <Loader />
          <AdminFooter />
        </div>
      </div>
      <ModalPopupComp
        id="modalDeleteReport"
        childrenBody={<PopupBody />}
        displayButtons={true}
        popupDiscardFunc={deleteReport}
      />
      <ModalPopupComp
        id="customReportWarningModal"
        childrenBody={<WarningBody />}
        displayButtons={true}
        popupDiscardFunc={navigateToSavedFilter}
      />
    </AdminWrapper>
  );
};
