import React, { useRef } from "react";

import { useTranslation } from "react-i18next";

import { components as comp } from "common/constants/customReports";

import _ from "lodash";

import { useDispatch, useSelector } from "react-redux";
import { SaveCustomReportByFilters } from "services/api/report.api";
import { setPageLoadingStatus } from "components/Loader/loader.slice";

import { useCustomReportContext } from "../CustomReportContext/CustomReportContext";

import store from "app/store";
import { showToast } from "components/Toast/toast.slice";

import { listCustomReportFiltersApiCall, setDisableSaveReportFilter } from "../CustomReportSlice";
import { setSavedFilterflag } from "components/AdminLeftPanel/LeftPanelSlice";

export const SaveReportBody = (props: any) => {
  const {
    setSavedReportName,
    setShowError,
    setReportNameErrorClass,
    savedReportName,
    reportNameErrorClass,
    showError,
    validateDateRange,
  } = props;

  const customReportingState = useSelector((state: any) => {
    return state.customReport;
  });

  const cancelButtonRef = useRef<HTMLInputElement>(null);

  const { customReportState, dispatch: customReportDispatch } =
    useCustomReportContext();

  const dispatch = useDispatch();

  const validateReportName = (value: any) => {
    if (_.isEmpty(value)) {
      setReportNameErrorClass(true);
      setShowError("Please enter a name for Report Filter!");
      return false;
    } else if (value.length < 2) {
      setReportNameErrorClass(true);
      setShowError("Report Filter Name must have more than 2 characters");
      return false;
    } else if (value.length > 255) {
      setReportNameErrorClass(true);
      setShowError("Report Filter Name must not exceed 255 characters");
      return false;
    } else {
      setReportNameErrorClass(false);
      setShowError("");
      return true;
    }
  };

  //Save Filter
  const handleSaveFilter = async (e: any) => {
    e.preventDefault();

    let reportData = { ...customReportingState.customReportApiReqBody };

    if (validateReportName(savedReportName)) {
      for (const [key, value] of Object.entries(reportData.filters)) {
        comp.forEach((item) => {
          if (item.code === _.capitalize(key) && typeof value === "string") {
            reportData.filters[key] = value.split(",");
          }
        });
      }


      let reportName = savedReportName;

      let saveFilterApiReqBody = {
        filters: {
          ...customReportingState.customReportApiReqBody,
          filters: {
            ...customReportingState.customReportApiReqBody.filters,
            notifyMe: customReportingState.notificationDetails?.notifyMe ?? false,
          }
        },
        name: reportName,
      };

      let dateRange;

      if (
        customReportingState.customReportApiReqBody?.filters?.startDate !== "" &&
        customReportingState.customReportApiReqBody?.filters?.endDate !== ""
      ) {
        dateRange = [
          new Date(customReportingState.customReportApiReqBody?.filters?.startDate),
          new Date(customReportingState.customReportApiReqBody?.filters?.endDate),
        ];
      }

      if (
        customReportingState.customReportApiReqBody?.filters?.frequency === "custom_range" &&
        !validateDateRange(dateRange)
      ) {
        cancelButtonRef.current?.click();
        return;
      }

      if (
        Object.keys(customReportingState.customReportApiReqBody).length !== 0 &&
        (customReportingState.customReportApiReqBody?.filters?.frequency !== "custom_range" ||
          validateDateRange(dateRange))
      ) {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        try {
          let response = await SaveCustomReportByFilters(saveFilterApiReqBody);

          if (response?.data?.status === 200) {
            setShowError("");
            setSavedReportName("");
            store.dispatch(
              showToast({
                type: "success",
                title: "CustomReport",
                message: "Report Filter Saved!",
              })
            );
            dispatch(setSavedFilterflag(true));
            cancelButtonRef.current?.click();
          }
        } catch (error: any) {
          if (error.response?.status === 400) {
            if (
              error?.response?.data?.error?.message?.startsWith(
                "Custom Report Filter "
              )
            ) {
              setReportNameErrorClass(true);
              setShowError(
                "Report Filter Name already exists. Please provide a different name"
              );
            }
            if (
              !(
                showError.startsWith("name") ||
                error?.response?.data?.error?.message?.startsWith(
                  "Custom Report Filter "
                )
              )
            ) {
              store.dispatch(
                showToast({
                  title: "CustomReport",
                  message:
                    error.response?.data?.error?.details?.[0]?.message ??
                    error.response?.data?.error?.message,
                })
              );
            }
          }
        }
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      }
    }
  };

  const { t } = useTranslation();
  return (
    <div className="save-report-modal-container mt-3">
      <form>
        <div className="form-group input-section required">
          <label className="input-section-label" htmlFor="name">
            {t<string>("name")}
          </label>
          <input
            className={`input-section-text form-control ${reportNameErrorClass ? "required-field" : ""
            }`}
            type="text"
            id="name"
            value={savedReportName}
            onChange={(e: any) => setSavedReportName(e.target.value)}
            required
          />
          <span
            className={`danger-text ${showError?.length > 0 ? "block" : "d-none"
            }`}
            data-testid="error_msg"
          >
            {showError.charAt(0).toUpperCase() + showError.slice(1)}
          </span>
        </div>
        <div className="button-section">
          <aui-button
            variant="secondary"
            buttontitle={t<string>("cancel")}
            onClick={() => {
              setReportNameErrorClass(false);
              setShowError("");
              setSavedReportName("");
            }}
            size="medium"
            data-dismiss="modal"
            data-testid="cancel_button"
            ref={cancelButtonRef}
          ></aui-button>
          <aui-button
            data-testid="save_button"
            variant="primary"
            buttontitle={t<string>("save")}
            onClick={handleSaveFilter}
            size="medium"
            type="submit"
          ></aui-button>
        </div>
      </form>
    </div>
  );
};
